import { Fragment, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Bars3BottomRightIcon } from '@heroicons/react/24/outline';
import logo from '../../../assets/icons/logo_navbar_w.svg';
import { useSession } from 'contexts';
import { AbilityContext } from 'contexts/AbilityContext';
import { OrgTypes } from 'utils/orgTypes';
import UserMenu from './UserMenu';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function BarSide(props) {
  const { organizationType } = useSession();
  const ability = useContext(AbilityContext);
  const href = useLocation().pathname;

  return (
    <div
      className={classNames(
        props.sidebarOpen ? 'w-0 md:w-72' : 'w-12',
        'transition-all ease-in-out duration-300 h-screen bg-brand-dark fixed z-40 flex-col justify-between hidden md:flex'
      )}>
      <div className="flex flex-col w-full h-full justify-between pt-6">
        <div className="overflow-y-auto overflow-x-clip">
          <div
            className={classNames(
              props.sidebarOpen ? 'px-6' : 'px-3',
              'flex justify-between items-center mb-8 w-full transition-all ease-in-out duration-300'
            )}>
            <Link
              to={organizationType === OrgTypes.PART145 ? '/mro/dashboard' : '/crafts'}
              className={classNames(props.sidebarOpen ? '' : 'w-0', 'h-10')}>
              <img src={logo} className="h-10" alt="Logo" />
            </Link>
            <Bars3BottomRightIcon
              className="h-6 w-6 text-white"
              aria-hidden="true"
              onClick={() => props.setSidebarOpen(!props.sidebarOpen)}
            />
          </div>
          <nav className="flex flex-col w-full">
            {props.navigation.map((item) => {
              if (item.hidden) return null;

              const isActive =
                href === item.href ||
                (item.href !== '/aircraft' && href?.replace('/aircraft', '').includes(item?.href?.replace('/aircraft', '')));

              const Icon = isActive ? item.iconActive : item.icon;
              

              return (
                <div key={item.name}>
                  {(ability.can(item.action, item.subject) || !item.action) && (
                    <Link
                      to={item.href}
                      className={classNames(
                        'flex items-center border-r-4 h-12 relative cursor-pointer text-sm',
                        isActive
                          ? 'text-white bg-brand font-bold border-brand-electric'
                          : 'font-semibold text-brand-pale border-brand-dark',
                        item.subMenu && props.sidebarOpen ? 'pl-20' : '',
                        props.sidebarOpen
                          ? 'hover:border-brand-electric px-6 transition-all ease-in-out justify-between'
                          : '',
                        'hover:text-white hover:bg-brand/50'
                      )}>
                      <div className="flex">
                        {Icon && (
                          <Icon
                            className={classNames(
                              props.sidebarOpen ? '' : 'absolute left-3',
                              'w-5 h-5 mr-2'
                            )}
                            aria-hidden="true"
                          />
                        )}
                        <span
                          className={classNames(
                            props.sidebarOpen ? 'opacity-100 transition-all ease-in-out duration-300' : 'opacity-0',
                            'whitespace-nowrap'
                          )}>
                          {item.name}
                        </span>
                      </div>
                      {props.sidebarOpen && <span>{item.badge ?? ''}</span>}
                    </Link>
                  )}
                </div>
              );
            })}
          </nav>
        </div>
        <div className="flex flex-col justify-end">
          <UserMenu altLoginUrl={props.altLoginUrl} sidebarOpen={props.sidebarOpen} />
        </div>
      </div>
    </div>
  );
}
