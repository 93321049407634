import { useMutation, useQuery } from '@apollo/client';
import logo from '../../../assets/icons/logo_navbar_w.svg';
import { GetOrganizationsDocument, SwitchOrganizationDocument } from 'graphql/generated';
import { useSession } from 'contexts';
import { useNavigate } from 'react-router-dom';

export default function OrganizationPicker() {
  const { organizationId, setOrganizationId, logout, user, organizationType, setCraftId, setLoginSession, setOrganizationType } = useSession();
  const { data: { organizations } = { organizations: [] } } = useQuery(GetOrganizationsDocument, { variables: { where: { userOrganizationProfiles: { 
      some: { user: { is:{ id: { equals: user?.id } } } } 
  } } } });
  const [switchOrganization] = useMutation(SwitchOrganizationDocument);
  const navigate = useNavigate();
  return (
    <div className='flex flex-col'>
      <div className='bg-brand w-full py-5 flex justify-between'>
        <img src={logo} className="h-10 ml-3" alt="" />
        <div>
          <span className='text-white font-semibold mr-3'>{user?.firstName + " " + user?.lastName}</span>
          <button 
            onClick={() => {
              logout();
              navigate('/auth');
            }}
          className='bg-brand-electric text-white px-3 py-1 rounded mr-3 font-bold hover:opacity-40'>Sign Out</button>
        </div>
      </div>

      <div className='p-3'>
        <span className='text-2xl font-bold text-brand-dark'>Select Organization</span>
        <div className='flex'>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-3 w-full'>
            {organizations.map((org) => { 
              const disabled = org?.cbInfo?.cb_status === 'cancelled' && org?.myRole !== 'Admin';
              return(
                <div
                onClick={() => {
                  setOrganizationId(org.id);
                  setCraftId(null);
                  navigate(organizationType === 'PART145' ? '/mro/dashboard' : '/crafts');
                }}
                key={org.id} className={`flex flex-col hover:opacity-40 bg-white p-5 m-3 rounded-lg border border-brand gap-4 w-full ${disabled && " opacity-45 cursor-not-allowed"} `}>
                  <span className='font-semibold text-lg'>{org?.name}</span>
                  <div className='flex justify-around flex-wrap'>
                    <div className='flex flex-col justify-center items-center'>
                      <span className='font-bold'>Aircraft:</span>
                      <span>{org?.crafts?.length}</span>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                      <span className='font-bold'>Users:</span>
                      <span>{org?.users?.length}</span>
                    </div>
                  </div>
                  <div className='flex flex-col text-center font-bold gap-2'>
                    <span>Role: {org?.myRole}</span>
                    {disabled && <span className='text-red-500'>Cancelled by Admin</span>}
                    {(org?.cbInfo?.cb_status === 'cancelled' || org?.cbInfo?.cb_status === 'paused') && <span className='text-red-500'>Cancelled</span>}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        
      </div>
    </div>
  );
}