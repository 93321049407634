import { ErrorMessage, Form, Formik } from 'formik';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { FormLabel, FullFormikInput, StyledFormikField } from 'components/Form/StandardForm';
import { useMutation } from '@apollo/client';
import { CraftType, CreateMroCustomerAndCraftDocument, GetMroCustomersDocument, NewOrderCreateCraftDocument, NewOrderCustomersDocument } from 'graphql/generated';
import { useSession } from 'contexts';

// DEFAULT
const CreateCraftBasicInfo = function (props) {
  const { user } = useSession();
  const [createCustomer] = useMutation(CreateMroCustomerAndCraftDocument, {
    refetchQueries: [NewOrderCustomersDocument, GetMroCustomersDocument],
  });
  const handleCraftSubmit = async ({ ...values }: any) => {
    const result = await createCustomer({
      variables: {
        input: {
          mroOrganizationId: user?.mroOrganizationId,
          craft:{
            tailNumber: values?.tailNumber.str[0] === 'N' ? values.tailNumber : 'N' + values.tailNumber,
            ...(values.year ? {year: Number(values.year ?? 0)} : { year: 2000 }),
            make: values.make,
            model: values.model,
            address:{
              title: 'Empty Address',
              address: '',
            }
          },
          customer: {
            name: values.name,
            email: values.email,
            address:{
              title: 'Empty Address',
              address: '',
            }
          },
          contact: {
            email: values.email,
            firstName: values.name.split(' ').slice(0, -1).join(' '),
            lastName: values.name.split(' ').slice(-1).join(' '),
          }
        },
      },
    });
    if (result.data?.onboardMroCustomer) {
      props.setSelectedCustomer(result.data?.onboardMroCustomer);
      props.setSelectedContact(result.data?.onboardMroCustomer?.mroContacts?.[0]);
    }
    props.setStep(4);
  };
  return (
    <Formik
      initialValues={{
        tailNumber: props.tailNumber,
        year: Number(props.tailInfo?.year),
        make: '' + (props.tailInfo?.manufacturer ?? ''),
        model: '' + (props.tailInfo?.model ?? ''),
        engineCount: props.tailInfo?.engineCount ?? 1,
        name: '',
        email: '',
      }}
      onSubmit={handleCraftSubmit}>
      <Form className="flex flex-col">
        <div className="flex flex-col mb-2">
          <span className="text-xl font-bold text-brand">Add an Basic Info</span>
          <span className="md:text-sm text-slate-500 mb-4">Which aircraft is being serviced?</span>
        </div>
        <div className="flex items-center justify-between p-2 w-full bg-brand-pale mb-6 rounded border-brand/30 border">
          <div className="flex items-center">
            <InformationCircleIcon className="h-4 w-4 mr-1 text-brand-electric opacity-50" />
            <span className="text-sm text-brand font-semibold">Data loaded from tail number</span>
          </div>
          <span className="text-xs font-bold bg-brand-electric/20 text-brand rounded px-2 py-1">{props.tailInfo?.nNumber ?? props?.tailNumber}</span>
        </div>
        <div className="flex gap-1">
          <div className="flex flex-col w-1/2 relative">
            <FormLabel>Tail Number</FormLabel>
            <StyledFormikField name="tailNumber" />
            <span className="absolute -bottom-4 right-0 text-red-500 text-xs">
              <ErrorMessage name="tailNumber" />
            </span>
          </div>
          <div className="flex flex-col w-1/2 relative">
            <FormLabel>Year</FormLabel>
            <StyledFormikField name="year" type="number" />
            <span className="absolute -bottom-4 right-0 text-red-500 text-xs">
              <ErrorMessage name="year" />
            </span>
          </div>
        </div>
        <div className="flex flex-col w-full relative">
          <FormLabel>Make</FormLabel>
          <StyledFormikField name="make" />
          <span className="absolute -bottom-4 right-0 text-red-500 text-xs">
            <ErrorMessage name="make" />
          </span>
        </div>
        <div className="flex gap-1">
          <div className="flex flex-col w-2/3 relative">
            <FormLabel>Model</FormLabel>
            <StyledFormikField name="model" />
            <span className="absolute -bottom-4 right-0 text-red-500 text-xs">
              <ErrorMessage name="model" />
            </span>
          </div>
          <div className="flex flex-col w-1/3 relative">
            <FormLabel>Engine Count</FormLabel>
            <StyledFormikField name="engineCount" type="number" min="1" />
            <span className="absolute -bottom-4 right-0 text-red-500 text-xs">
              <ErrorMessage name="engineCount" />
            </span>
          </div>
        </div>
        <hr className='my-2' />
        <div className="flex gap-1">
          <div className="flex flex-col w-1/2 relative">
            <FormLabel>Name</FormLabel>
            <FullFormikInput name="name" required={true} />
            <span className="absolute -bottom-4 right-0 text-red-500 text-xs">
              <ErrorMessage name="name" />
            </span>
          </div>
          <div className="flex flex-col w-1/2 relative">
            <FormLabel>Email</FormLabel>
            <FullFormikInput name="email" type="email" required={true} />
            <span className="absolute -bottom-4 right-0 text-red-500 text-xs">
              <ErrorMessage name="email" />
            </span>
          </div>
        </div>
        <div className="flex justify-between items-center mt-8">
          <button
            type="button"
            onClick={() => {
              props.setStep(1);
            }}
            className="underline text-brand font-semibold text-sm">
            Go Back
          </button>
          <button
            type="submit"
            className="flex items-center bg-brand text-white border border-brand text-sm rounded font-semibold py-2 px-4">
            Create Customer
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export default CreateCraftBasicInfo;
