import { useState } from 'react';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import { useMutation, useQuery } from '@apollo/client';
import { GetMroCraftDocument, GetMroCraftsDocument, RemoveMroCraftDocument } from 'graphql/generated';
import { useNavigate, useParams } from 'react-router-dom';
import { AirplaneIcon } from 'assets/icons';
import CraftOrder from './Partials/CraftOrder';
import CraftLogs from './Partials/CraftLogs';
import CraftComponents from './Partials/CraftComponents';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import EditCraft from './Edit';
import Modal from 'components/Modal/Modal';
import { useSession } from 'contexts';

const Craft = function () {
  const [currentTab, setCurrentTab] = useState('overview');
  const { craftId } = useParams<{ craftId: string }>();
  const { user } = useSession();
  const [ showModal, setShowModal ] = useState(false);
  const { data: { mroCraft } = {} } = useQuery(GetMroCraftDocument, {
    skip: !craftId,
    variables: {
      mroCraftId: craftId,
    },
  });
  const [ deleteMroCraft ] = useMutation(RemoveMroCraftDocument, {
    refetchQueries: [{
      query: GetMroCraftsDocument,
      variables: { where: { mroOrganizationId: { equals: user.mroOrganizationId } }}
    }],
    onCompleted: () => navigate('/mro/crafts')
  });
  
  const { updateFlyout, flyoutProps, closeFlyout } = useFlyout();
  const navigate = useNavigate();
  return (
    <div className='mb-24'>
      { showModal && <Modal message={"You are about to delete this craft"} cancel={setShowModal} accept={() =>
        deleteMroCraft({ variables: { id: craftId } })
      }/> }
      <Flyout {...flyoutProps} />
      <div className="flex flex-col">
        <div className="bg-white border rounded border-slate-300">
          <div className="flex rounded-t items-end justify-between p-6">
            <div className="flex items-center">
              <div className="bg-brand-pale rounded h-12 w-12 flex items-center justify-center">
                <AirplaneIcon className="h-6 w-6 text-brand-electric" />
              </div>
              <div className="ml-2 flex flex-col">
                <h1 className="text-brand-dark text-left font-bold text-xl md:text-2xl">{mroCraft?.tailNumber}</h1>
                <div className="-mt-0.5 text-sm text-slate-500">
                  <span className="font-medium mr-1 pr-1 border-slate-300">{mroCraft?.make}</span>
                  <span className="mr-1 pr-1">{mroCraft?.model}</span>
                  <span>{mroCraft?.year}</span>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="flex-col mr-3 text-center bg-brand-offwhite border-brand-pale border text-brand-electric rounded pt-0.5 px-1">
                <h3 className="font-bold text-brand-electric uppercase text-sm -mb-1">{mroCraft?.mroWorkOrders?.length}</h3>
                <span className="text-xs text-brand uppercase font-bold">Work Orders</span>
              </div>
              <Menu
                as="div"
                className="flex items-center justify-center relative group transition ml-2 w-[28px] border border-white rounded hover:border-slate-200 hover:shadow-inner">
                <Menu.Button className="">
                  <EllipsisVerticalIcon className="h-5 w-5 text-slate-500 cursor-pointer" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95">
                  <Menu.Items className="origin-top-right absolute top-full right-0 mt-2 rounded shadow-blue-lg border border-slate-300 bg-white focus:outline-none z-[99] w-36">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${active ? 'bg-slate-100' : ''} block px-4 py-3 text-sm text-slate-700 w-full text-right rounded`}
                          onClick={() => updateFlyout({title: "Update Craft", content: <EditCraft mroCraft={mroCraft} closeFlyout={closeFlyout} />})}>
                          Edit
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${active ? 'bg-slate-100' : ''} block px-4 py-3 text-sm text-slate-700 w-full text-right rounded`}
                          onClick={() => setShowModal(true)}>
                          Delete
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <div className="flex items-end p-4 px-6 bg-slate-50 border-slate-300 border-t rounded-b w-full overflow-hidden">
            <button
              onClick={() => mroCraft?.mroCustomer?.id && navigate('/mro/customers/' + mroCraft?.mroCustomer?.id)}
              className={`flex items-center justify-center place-self-center font-bold text-base text-brand-electric bg-brand-pale border-brand-electric w-8 h-8 rounded mr-2`}>
              {mroCraft?.mroCustomer?.name?.[0] ?? 'UC'}
            </button>
            <div className="flex flex-col">
              <span className="text-lg font-bold text-brand-dark">{mroCraft?.mroCustomer?.name}</span>
              <span className="text-brand-dark">{mroCraft?.mroCustomer?.email}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-start pt-2 -mb-[1px]">
        <button
          className={`${
            currentTab !== 'overview' ? 'bg-slate-50 text-slate-500' : 'bg-white border-b-white'
          } text-sm p-2 md:px-4 font-semibold rounded-t border border-slate-300`}
          disabled={currentTab === 'overview'}
          onClick={() => setCurrentTab('overview')}>
          Work Orders
        </button>
        <button
          className={`${
            currentTab !== 'logs' ? 'bg-slate-50 text-slate-500' : 'bg-white border-b-white'
          } text-sm p-2 md:px-4 font-semibold rounded-t border border-slate-300`}
          disabled={currentTab === 'logs'}
          onClick={() => setCurrentTab('logs')}>
          Logs
        </button>
        <button
          className={`${
            currentTab !== 'components' ? 'bg-slate-50 text-slate-500' : 'bg-white border-b-white'
          } text-sm p-2 md:px-4 font-semibold rounded-t border border-slate-300`}
          disabled={currentTab === 'components'}
          onClick={() => setCurrentTab('components')}>
          Components
        </button>
      </div>

      {/* OVERVIEW TAB */}
      <div hidden={currentTab !== 'overview'} className="bg-slate-100 shadow-inner border rounded-b rounded-r border-slate-300">
        <div className="p-2 rounded-tr bg-white"></div>
        <div className="flex flex-col border-brand-pale transition-all border border-t-0 p-4 shadow-inner bg-brand-offwhite rounded-b gap-2">
          {mroCraft?.mroWorkOrders.map((order, ndx) => {
            return <CraftOrder key={ndx} order={order} />;
          })}
        </div>
        <div className="p-2 rounded-tr bg-white"></div>
      </div>
      {/* LOGS TAB */}
      <div hidden={currentTab !== 'logs'} className="bg-slate-100 shadow-inner border rounded-b rounded-r border-slate-300">
        <CraftLogs mroWorkOrders={mroCraft?.mroWorkOrders} closeFlyout={closeFlyout} updateFlyout={updateFlyout} />
      </div>
      {/* COMPONENTS TAB */}
      <div hidden={currentTab !== 'components'} className="bg-slate-100 shadow-inner border rounded-b rounded-r border-slate-300">
        <CraftComponents mroCraftId={mroCraft?.id} closeFlyout={closeFlyout} updateFlyout={updateFlyout} />
      </div>
    </div>
  );
};

export default Craft;
