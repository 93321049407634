import { useQuery } from '@apollo/client';
import { LockClosedIcon, PlusIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import { Table } from 'components/Table/Table';
import { useSession } from 'contexts';
import { GetUserOrganizationProfilesDocument } from 'graphql/generated';
import { useContext, useMemo } from 'react';
import NewUser from './Flyouts/NewUser';
import { webFormat } from 'utils/statuses';
import EditUser from './Flyouts/EditUser';
import Paywall from 'components/Paywall/Paywall';
import { OrgSubtypes } from 'utils/orgTypes';
import { AbilityContext } from 'contexts/AbilityContext';
import { permissionHandler } from 'utils/PermissionHandler';

export default function UsersSettings() {
  const { organizationId, organizationSubtype } = useSession();
  const ability = useContext(AbilityContext); 
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const { data : { userOrganizationProfiles } = {} } = useQuery( GetUserOrganizationProfilesDocument, { variables: { where: { organizationId: { equals: organizationId } } } } );
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: (row: any) => ({
          user: row.user,
          nameInvite: row.nameInvite,
         }), // accessor is the "key" in the data
        className: 'w-3/12',
        Cell: ({ value, row }: any) => {
          const isLocked = row.original.locked;
          return <div className={`flex gap-2 ${isLocked && '-ml-5'}`}>
            { isLocked && <LockClosedIcon className="w-4 h-4" /> }
            { value?.user?.firstName ? 
              <span className="font-semibold">{value.user.firstName} {value.user.lastName}</span> : 
              <span className="font-semibold">{value?.nameInvite ?? 'Unknown'}</span> }
          </div>
        },
      },
      {
        Header: 'Email', 
        accessor: (row: any) => ({
          email: row?.user?.email,
          emailInvite: row.emailInvite,
         }), // accessor is the "key" in the data
        className: 'truncate w-3/12 items-start flex p-4',
        Cell: ({ value, row }: any) => {
          return <span className="font-bold text-brand-dark rounded py-1">{value?.email ?? value?.emailInvite}</span>;
        },
      },
      {
        Header: 'Phone', 
        accessor: (row: any) => ({
          phone: row?.phone,
          phoneInvite: row.phoneInvite,
         }), // accessor is the "key" in the data
        className: 'w-2/12 items-start',
        Cell: ({ value, row }: any) => {
          return <span className="font-bold text-brand-dark rounded py-1">{value?.phone ?? value?.phoneInvite}</span>;
        },
      },
      {
        Header: 'Status', 
        accessor: 'status', // accessor is the "key" in the data
        className: 'w-2/12 items-start',
        Cell: ({ value, row }: any) => {
          return <span className="">{webFormat(value)}</span>;
        },
      },
      {
        Header: 'Role', 
        accessor: (row: any) => row?.userOrganizationRole?.title, // accessor is the "key" in the data
        className: 'w-2/12 items-start',
        Cell: ({ value, row }: any) => {
          return <div className='flex'>
            <span className="">{value}</span>
          </div>;
        },
      },
      
    ],
    []
  );  
  return (
    <>
      <Flyout {...flyoutProps} />
      {organizationSubtype === OrgSubtypes.FREE ? 
        <Paywall title="Adding more users is a premium feature!" />
        :
        <div className="flex flex-col lg:flex-row justify-center">
          <Card title='Coflyt Users' className="flex flex-col p-4 h-full w-full">
            { permissionHandler('Add Users', ability, organizationSubtype) && <div className='w-full flex justify-end -mt-12'>
              <Button text="Add New User" className='justify-end' icon={<PlusIcon className="w-4 h-4 mr-2" />} size='xs' color='navy' onClick={(e) => updateFlyout({ title: 'Add User', content: <NewUser closeFlyout={closeFlyout} /> }) }/>
            </div> }
            <Table className='mt-5 w-full' columns={columns}
              onRowClick={(row) => {
                if(row.userOrganizationRole.title === 'Admin') return;
                if(permissionHandler('Add Users', ability, organizationSubtype)) {
                  updateFlyout({ title: 'Edit User', content: <EditUser closeFlyout={closeFlyout} id={row?.id} /> });
                }
              }}
              data={[...userOrganizationProfiles ?? []]?.sort((a,b) => 
                new Date(b?.createdAt)?.getTime() > new Date(a?.createdAt)?.getTime() ? -1 : 1
              )
              .map((data) => ({
                ...data,
                locked: data?.userOrganizationRole?.title === 'Admin',
              }))} />
          </Card>
        </div>
      }
    </>
  );
}
