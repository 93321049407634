import { useMutation, useQuery } from "@apollo/client";
import Button from "components/Button/Button";
import { FullFormikInput, FullFormikSelect } from "components/Form/StandardForm";
import Toast, { ToastLength, useToast } from "components/Toast/Toast";
import { useSession } from "contexts";
import { Form, Formik } from "formik";
import { GetMroEmployeeDocument, GetMroRolesDocument, UpdateMroEmployeeDocument } from "graphql/generated";

export function EmployeeSettings({ employeeId } : { employeeId: string }) {
    const { toastProps, showToast } = useToast();
    const { user } = useSession();
    const { data: { mroEmployeeProfile } = {} } = useQuery(GetMroEmployeeDocument, {
        variables: { mroEmployeeId: employeeId },
    });
    const { data: { mroOrganization } = {} } = useQuery(GetMroRolesDocument, { variables: { mroOrganizationId: user.mroOrganizationId } });
    const roles = mroOrganization?.mroRoles;
    const [updateEmployee] = useMutation(UpdateMroEmployeeDocument, {
        refetchQueries: [{ query:GetMroEmployeeDocument, variables: { mroEmployeeId: employeeId } }],
    });
    const initialValues = {
        name: mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName,
        email: mroEmployeeProfile?.email,
        phone: mroEmployeeProfile?.phone,
        costRate: mroEmployeeProfile?.costRate,
        billableRate: mroEmployeeProfile?.billableRate,
        mroRoleId: mroEmployeeProfile?.mroRole?.id,
    }
    return (
        <div>
            <Toast {...toastProps} position="top" />
            <Formik initialValues={initialValues} onSubmit={async (values) => {
                await updateEmployee({
                    variables: {
                        input: {
                            id: employeeId,
                            firstName: values.name.split(' ')[0],
                            lastName: values.name.split(' ')[1],
                            email: values.email,
                            phone: values.phone,
                            costRate: values.costRate,
                            billableRate: values.billableRate,
                            mroRole: { connect: { id: values.mroRoleId }  }
                        },
                    },
                });
                showToast({ title: 'Employee Updated', type: ToastLength.Normal });
            }} enableReinitialize>
                <Form>
                    <FullFormikInput name="name" label="Name" />
                    <FullFormikInput name="email" label="Email" />
                    <FullFormikInput name="phone" label="Phone Number" />
                    <div className="flex gap-3">
                        <FullFormikInput name="costRate" label="Cost Rate" />
                        <FullFormikInput name="billableRate" label="Billable Rate" />
                    </div>
                    <FullFormikSelect name="mroRoleId" label="Role">
                        <option value="" className="text-brand/80" disabled>
                            Select a Role
                        </option>
                        {roles?.map((role) => (
                            <option value={role.id} key={role.id}>
                                {role.title}
                            </option>
                        ))}
                    </FullFormikSelect>
                    <div className="flex justify-end gap-5 items-center mt-4">
                        {mroEmployeeProfile?.inviteLink && <Button text="Copy Invite Link" color="electric" size="sm" onClick={() =>{
                            showToast({ title: 'Invite Link Copied', type: ToastLength.Normal });
                            navigator.clipboard.writeText(mroEmployeeProfile?.inviteLink);
                        }} />}
                        <Button text="Save" color="navy" size="sm" type="submit" />
                    </div>
                </Form>
            </Formik>
        </div>
    );
}