import { Table } from "components/Table/Table";
import { useEffect, useMemo, useState } from "react";
import { GetReservationsDocument, Reservation } from "graphql/generated";
import { applyTimezone, formatApiDateTimeUTC, formatApiDateUTC } from "utils/formatter";
import { useSession } from "contexts";
import View from "../View";
import { useQuery } from "@apollo/client";
import { useRefetchContext } from "contexts/RefetchContext";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

function List({ updateFlyout, closeFlyout } : { updateFlyout: any, closeFlyout: any }) {
  const { organizationId } = useSession();
  const { user } = useSession();
  const [ limit, setLimit ] = useState(10);
  const [ skip, setSkip ] = useState(0);
  const [ sortBy, setSortBy ] = useState(['from', 'desc']);
  const { data, refetch } = useQuery(GetReservationsDocument, { 
    variables: { 
      where: { craft: { is:{ organizationId: { equals: organizationId } } } },
      limit: limit,
      skip: skip,
      orderBy: { [sortBy[0]] : { sort: sortBy[1] } },
    },
  } );

  // Set refetch function to context
  const { setRefetchList } = useRefetchContext();
  useEffect(() => {
    setRefetchList(() => refetch);
  }, [refetch, setRefetchList]);

  const showView = (reservation)=> {
    updateFlyout({ title: 'Reservation', content: <View refreshData={refetch} id={reservation?.id} closeView={closeFlyout} /> });
  }
  const columns = useMemo(
      () => [
        {
          Header: 'From',
          accessor: 'from',
          className: 'text-left w-1/3 sm:w-2/12 text-xs sm:text-sm min-w-20',
          Cell: ({ value, row }: any) => {
            return (
              <div className="text-left sm:ml-0 ml-[-20px]">
                <button>{row?.original?.allDay? formatApiDateUTC(applyTimezone(value, Number(row?.original?.timezone ?? 0), Number(user.timezone))) : formatApiDateTimeUTC(applyTimezone(value, Number(row?.original?.timezone ?? 0), Number(user.timezone)).toISOString())}</button>
              </div>
            );
          },
        },
        {
          Header: 'To',
          accessor: 'to',
          className: 'text-left w-1/3 sm:w-2/12 pl-4 text-xs sm:text-sm min-w-20',
          Cell: ({ value, row }: any) => {
            return (
              <div className="text-left sm:ml-0 ml-[-20px]">
                <button>{row?.original?.allDay ? formatApiDateUTC(applyTimezone(value, Number(row?.original?.timezone ?? 0), Number(user.timezone))) : formatApiDateTimeUTC(applyTimezone(value, Number(row?.original?.timezone ?? 0), Number(user.timezone)).toISOString())}</button>
              </div>
            );
          },
        },
        {
          Header: 'Craft',
          accessor: (row: any) => `${row?.craft?.craftName ?? ''}`,
          className: 'text-left w-1/3 sm:w-2/12 min-w-28 pl-5 text-xs sm:text-sm',
        },
        {
          Header: 'Pilot',
          accessor: (row: any) => `${row.isMaintenance ? "Maintenance" : row?.pilotName ?? ''}`,
          className: 'text-left w-2/12  min-w-28',
        },
        {
          Header: 'Notes',
          accessor: 'notes  min-w-36',
          className: 'text-center w-4/12 truncate overflow-hidden text-ellipsis',
        },
      ],
      []
  );
  return ( 
  <div className="p-2 sm:p-6 bg-white border rounded border-slate-300 w-full shadow-blue">
    <Table
      className="w-full"
      columns={columns}
      data={data?.reservations ?? []}
      onRowClick={showView}
      setSortBy={(sort) => setSortBy(sort)}
      skip={skip} limit={limit} count={data?.reservationCount ?? 0}
      updateLimitAndSkip = {(lm, sk) => { setLimit(lm); setSkip(sk); }}
      mobileRowCount={3}
    />
  </div>);
}
export default List;