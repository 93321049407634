import { useState } from 'react';
import UserIcon from 'components/UserIcon/UserIcon';
import { CaretRightIcon, CaretDownIcon } from 'assets/icons';
import { useNavigate } from 'react-router-dom';

const EmployeeCard = function ({ cat, tableData }) {
  const [hideCat, setHideCat] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="mb-6 last:mb-0">
      <div
        onClick={() => {
          setHideCat(!hideCat);
        }}
        className="flex items-center gap-2 mb-2 cursor-pointer">
        <h2 className="font-bold text-xs uppercase pl-1">
          {cat} — {tableData[cat].length}
        </h2>
        {hideCat ? <CaretRightIcon className="h-3 w-3 rounded-full text-slate-500" /> : <CaretDownIcon className="h-3 w-3 rounded-full" />}
      </div>
      {!hideCat && (
        <div className="flex flex-wrap gap-2">
          {tableData[cat].map((item) => {
            const accessor = {
              name: [item.firstName, item.lastName].join(' '),
              end: item.latestActivity?.endTime,
              active: item.latestActivity,
              accountSetup: item.accountSetup,
            };
            return (
              <div key={item.id} className="w-full xl:w-[calc(50%-0.5rem)] cursor-pointer">
                <div
                  onClick={() => navigate(`/mro/employees/${item.id}`)}
                  className="flex items-stretch w-full rounded group">
                  <div
                    className={`${!accessor.active ? 'border-slate-200 bg-slate-50' : 'border-brand-pale bg-white'} 
                    flex items-center justify-between w-full p-4 transition-all rounded border shadow-sm shadow-brand-offwhite hover-shadow-blue`}>
                    <div className="flex items-center gap-2">
                      <UserIcon
                        iconSize="lg"
                        iconText={accessor.name}
                        iconId={accessor.name}
                        iconColor={item.profileColor}
                        className={!accessor.active && 'bg-slate-300 group-hover:bg-slate-400/70 transition'}
                      />
                      <div
                        className={`${
                          accessor.active ? 'text-slate-500' : 'text-slate-400'
                        } flex flex-col group-hover:text-slate-500 transition`}>
                        <div className="flex items-center gap-2">
                          <span
                            className={`${
                              accessor.active ? 'text-brand-dark' : 'text-slate-500 group-hover:text-brand-dark'
                            } text-lg font-bold transition`}>
                            {accessor.name}
                          </span>
                          <span className="text-sm font-medium">{item.phone}</span>
                        </div>
                        <span className="text-sm -mt-1 font-medium">
                          {(() => {
                            if(!accessor?.accountSetup){
                              return <span className="text-sm font-medium text-slate-400 pr-1.5 mr-1.5 border-slate-300 border-r">Not Setup </span>
                            }else if(accessor.active){
                              return <span className="text-sm font-medium text-brand-electric pr-1.5 mr-1.5 border-slate-300 border-r">Active </span>
                            }else{
                              return <span className="text-sm font-medium text-slate-400 pr-1.5 mr-1.5 border-slate-300 border-r">Inactive </span>
                            }
                          })()}
                          {item.latestActivity?.mroWorkOrderItem?.title || 'None'}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-4">
                      <CaretRightIcon className="h-4 w-4 rounded-full" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default EmployeeCard;
