import { useMutation, useQuery } from "@apollo/client";
import Button from "components/Button/Button";
import DatePicker from "components/DatePicker/DatePicker";
import { FormLabel, FullFormikInput, FullFormikSelect } from "components/Form/StandardForm";
import { useSession } from "contexts";
import { Field, Form, Formik } from "formik";
import { CreateMroReportDocument, GetMroReportTypesDocument } from "graphql/generated";
import * as Yup from 'yup';

const NewReportSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    startDate: Yup.date().required('Required'),
    endDate: Yup.date().required('Required'),
});

const NewReports = function (props) {
    const { user } = useSession();
    const initialValues = {
        title: '',
        type: 'partInventory',
        startDate: new Date(),
        endDate: new Date(),
    };

    const [createReport] = useMutation(CreateMroReportDocument, {
        refetchQueries: [],
    });

    const { data : { reportTypes } = {} } = useQuery(GetMroReportTypesDocument);
    return (
        <div className="p-4">
          <Formik initialValues={initialValues} onSubmit={(values) => {
            createReport({
                variables: {
                    input:{
                        title: values.title,
                        type: values.type,
                        date: new Date(),
                        mroOrganization: { connect: { id: user?.mroOrganizationId } },
                        endDate: values.endDate,
                        startDate: values.startDate,
                    }
                }
            });
          }} validationSchema={NewReportSchema} enableReinitialize>
            {({ values }) => (
              <Form>
                <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
                    <div className="flex gap-1">
                        <FullFormikInput name="title" label="Title*" />
                    </div>
                    <FullFormikSelect name="type" label="Report Type*">
                        { reportTypes?.map((type) => (
                            <option key={type.id} value={type.id}>
                                {type.title}
                            </option>
                        ))}
                    </FullFormikSelect>
                    <div className='flex flex-col w-full'>
                        <FormLabel>Start Date*</FormLabel>
                        <Field as={DatePicker} className="w-full" name="startDate" />
                    </div>
                    <div className='flex flex-col w-full'>
                        <FormLabel>End Date*</FormLabel>
                        <Field as={DatePicker} className="w-full" name="endDate" />
                    </div>
                </div>
                <div className="flex justify-end items-center mt-4">
                  <Button text="Generate Report" color="navy" size="xs" type="submit" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
    );
}

export default NewReports;