import { useMutation, useQuery } from "@apollo/client";
import Button from "components/Button/Button";
import { FullFormikSelect } from "components/Form/StandardForm";
import Toast, { ToastLength, useToast } from "components/Toast/Toast";
import { useSession } from "contexts";
import { Form, Formik } from "formik";
import { GetMroEmployeeDocument, UpdateMroEmployeeDocument } from "graphql/generated";
import { useEffect, useState } from "react";
import { webFormat } from "utils/statuses";

const NotificationsOptions = () => ( <>
    <option value={"NONE"} >No Notifications</option>
    <option value={"EMAIL"}>Email</option>
</>)

const notificationTypes = [
    "NEW_SERVICE_REQUESTS",
    "ESTIMATE_AUTHORIZATION",
    "ESTIMATE_SENT_TO_CUSTOMER",
    "INVOICE_SENT_TO_CUSTOMER",
    "ASSIGNED_WORK_ORDER",
    "PART_REQUEST_APPROVAL",
    "PART_REQUEST_ALLOCATION",
]

export interface NotificationSettings {
    notification: string;
    type: string;
}

export default function NotificationsSettings() {
    const { user } = useSession();
    const { data: { mroEmployeeProfile: { notificationSettings: settings } = {} } = {} } = useQuery(GetMroEmployeeDocument, {
        variables: { mroEmployeeId: user?.mroEmployeeProfileId },
    });
    const [updateEmployee] = useMutation(UpdateMroEmployeeDocument);

    const [ notificationSettings, setNotificationSettings ] = useState(notificationTypes.reduce(function(acc, cur, i) {
        acc[cur] = "NONE";
        return acc;
    }, {}));

    useEffect(() => {
        if (settings) {
            setNotificationSettings(settings);
        }
    }, [settings]);

    const [isLoadingSave, setIsLoadingSave] = useState(false);


    const { toastProps, showToast } = useToast();
    return (
        <div className="flex">
            <Toast {...toastProps} />
            <Formik initialValues={ notificationSettings } enableReinitialize onSubmit={ async (values)=> { 
                setIsLoadingSave(true);
                await updateEmployee({ variables:{ input: { notificationSettings: values, id: user.mroEmployeeProfileId } } })
                setTimeout(() => { 
                    showToast({ title: 'Notfication Preferences Updated', type:ToastLength.Normal });
                    setIsLoadingSave(false);
                  }, 1500);
            }} >
                <Form className="flex flex-col w-full md:w-2/3 gap-2 p-10 rounded-l">
                    <span className="font-bold text-xs text-brand uppercase mb-6 border-b py-0.5 -mx-4 pl-4">Notification Settings</span>
                    { notificationTypes.map((type, i) => ( 
                        <div className="flex items-center gap-2" key={i}>
                            <span className="w-1/3 text-sm font-medium text-slate-500">{ webFormat(type) }</span>
                            <FullFormikSelect name={type}>
                                <NotificationsOptions />
                            </FullFormikSelect> 
                        </div>
                    )) }
                    
                    <Button text="Save" color="navy" size="sm" type="submit" disabled={isLoadingSave} className="disabled:opacity-50" />
                </Form>
            </Formik>
        </div>
    )
}