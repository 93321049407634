import { useLazyQuery, useQuery } from "@apollo/client";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Button from "components/Button/Button";
import { useSession } from "contexts";
import { StripePayPartialDocument } from "graphql/generated";
import { useState } from "react";

export default function PartialPaymentModal({ maxPayment, cancel } : { maxPayment: number, cancel: (boolean)=>void }) {
    const [payment, setPayment] = useState(maxPayment?.toFixed(2));
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { user } = useSession();
    const [ getStripeLink ] = useLazyQuery(StripePayPartialDocument);

    const handlePayment = async () => {
        if(isNaN(Number(payment))){
            setError('Payment amount must be a number');
            return;
        }
        if (Number(payment) < 1) {
            setError('Payment amount must be greater than $1');
            return;
        }
        if (Number(payment) > maxPayment) {
            setError('Payment amount must be less than or equal to the maximum payment amount');
            return;
        }
        setLoading(true);
        const res = await getStripeLink({ variables: { id: user?.userOrganizationProfileId, amount: Number(payment) } });
        if(res.data?.stripePayPartial){
            cancel(false);
            window.open(res.data?.stripePayPartial, '_self');
        }else{
            setError('Failed to create payment link');
        }
        setLoading(true);
    };
    const fieldName = `rounded border border-slate-300 bg-slate-50 h-12 md:h-10 mb-3 px-2 text-brand focus:shadow-inner focus:bg-white placeholder:text-slate-400 placeholder:text-sm disabled:bg-slate-100 disabled:cursor-not-allowed`;

    return (
        <div className="flex items-center justify-center fixed h-screen w-screen top-0 left-0 z-[100]">
            <div onClick={() => {
                cancel(false); }} className="bg-slate-100/50 backdrop-blur w-full h-full absolute top-0 left-0">
            </div>
            <div className="flex flex-col gap-2 items-center justify-center bg-white rounded border border-slate-300 relative hover-shadow max-w-md w-full">
                <XMarkIcon
                    onClick={() => {
                        cancel(false);
                    }}
                    className="h-4 w-4 absolute top-6 right-4 text-slate-500 cursor-pointer hover:text-slate-300 transition"
                />
                <div className="flex flex-col gap-5 items-center p-8">
                    <span className="font-semibold text-lg" >Type in Partial Payment</span>
                    <input inputMode="decimal" className={fieldName+" w-48 text-center"} value={payment} onChange={(e) => setPayment(e.target.value)} />
                    {!error && <div className="text-xs leading-5">Must be less than or equal to ${maxPayment.toFixed(2)} and more than $1</div>}
                    {error && <div className="text-sm text-center text-red-500 py-2 leading-5">{error}</div>}
                    {loading ? 
                        <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-slate-500">

                        </div>
                    : <Button color="navy" size="xs" text="Pay" disabled={loading} onClick={handlePayment} />}
                </div>
            </div>
        </div>
    );
}