import { useState, useEffect } from 'react';
import { CogIcon } from '@heroicons/react/24/solid';
import { useMutation, useQuery } from '@apollo/client';
import { GetMroPartDocument, UpdateMroPartDocument } from 'graphql/generated';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'components/Button/Button';
import { Formik, Form } from 'formik';
import { FullFormikInput } from 'components/Form/StandardForm';
import { formatApiDateTime } from 'utils/formatter';
import TabCards from 'components/TabCards/TabCards';

const EditPart = function () {
  const [initialValues, setInitialValues] = useState({
    partNumber: '',
    description: '',
    manufacturer: '',
  });
  const navigate = useNavigate();
  const { partId } = useParams();
  const { data: { mroPart } = {} } = useQuery(GetMroPartDocument, { variables: { partId } });
  const [updatePart] = useMutation(UpdateMroPartDocument, {
    refetchQueries: [{ query: GetMroPartDocument, variables: { partId } }],
  });
  useEffect(() => {
    if (mroPart) {
      setInitialValues({
        partNumber: mroPart.partNumber,
        description: mroPart.description,
        manufacturer: mroPart?.manufacturer?.name,
      });
    }
  }, [mroPart]);
  const tabs = [
    {
      title: 'Overview',
      content: (
        <div className="bg-white border rounded-b rounded-r border-slate-300 shadow-blue mb-10">
          {/* Part Info */}
          <div className="p-4">
            <div className="flex flex-col">
              <span className="font-semibold text-xs uppercase text-slate-500">Description</span>
              <FullFormikInput name="description" className="text-lg font-bold text-brand-dark leading-5" />
            </div>
            <div className="flex flex-col mt-4">
              <span className="font-semibold text-xs text-slate-500 uppercase">Created</span>
              <p className="font-medium text-brand-dark rounded text-sm">{formatApiDateTime(mroPart?.createdAt)}</p>
            </div>
          </div>
        </div>
      ),
    },
    { title: 'Requests', content: <></> },
    { title: 'Orders', content: <></> },
  ];
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        updatePart({
          variables: {
            input: {
              id: partId,
              partNumber: values.partNumber,
              description: values.description,
              manufacturer: { update: { data:{ name: values.manufacturer } } },
            },
          },
        })
        .then(() => navigate('../'))
        .catch(console.error);
      }}>
      <Form className="flex flex-col gap-5 mb-24">
        <div className="flex items-center justify-between w-full mb-2 px-2">
          <h1 className="text-2xl font-bold">Editing</h1>
          <div className="flex gap-2">
            <Button type="button" text="Cancel" color="white" size="xs" onClick={() => navigate('../')} />
            <Button type="submit" text="Save Changes" color="navy" size="xs" />
          </div>
        </div>
        <div className="bg-white border rounded border-slate-300 shadow-blue">
          {/* HEADER SECTION */}
          <div className="flex flex-col">
            <div className="flex rounded-t items-center justify-between p-6">
              <div className="flex items-center">
                <CogIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
                <FullFormikInput name="partNumber" className="text-brand-dark text-left font-bold text-xl md:text-2xl mt-2" />
              </div>
            </div>
            <div className="flex justify-between items-end p-4 px-6 bg-slate-50 border-slate-300 border-t rounded-b w-full overflow-hidden">
              <ul className="flex">
                <li className="flex-col mr-4">
                  <FullFormikInput name="manufacturer" className="font-bold text-brand-dark leading-5 h-9 w-25" />
                  <span className="text-xs text-slate-500">MANUFACTURER</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <TabCards items={tabs} setActiveTitle={() => {}} readOnly={true} activeTitle={tabs[0].title} />
      </Form>
    </Formik>
  );
};

export default EditPart;
