import { Fragment, useContext } from 'react';
import { useSession } from 'contexts/SessionContext';
import { GearIcon } from '../../../../assets/icons';
import { useQuery } from '@apollo/client';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import { GetInspectionFreeLimitDocument, GetInspectionsDocument, GetIntervalsForCraftDocument, IntervalSubtype, IntervalType, LogType } from 'graphql/generated';
import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/solid';
import New from './New';
import InspectionGroup from './Components/InspectionGroup';
import CraftIntervals from './Components/CraftsIntervals';
import Button from 'components/Button/Button';
import { DocumentCheckIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import ADSFlyout from './ADS/ADSFlyout';
import { OrgSubtypes } from 'utils/orgTypes';
import isMobileApp from 'utils/isMobileApp';
import { Navigate, useNavigate } from 'react-router-dom';
import { AbilityContext } from 'contexts/AbilityContext';
import { permissionHandler } from 'utils/PermissionHandler';

const Status = function () {
  const { updateFlyout, flyoutProps, closeFlyout } = useFlyout();
  const { craftId, organizationId, organizationSubtype } = useSession();
  
  // check if craftId is undefined and navigate to crafts page
  const navigate = useNavigate();
  if(!craftId) navigate('/crafts');
  
  const { loading, error, data } = useQuery(GetInspectionsDocument, { variables: { craftId } });
  const { data : { craft } = {  } } = useQuery(GetIntervalsForCraftDocument, { variables: { craftId } });
  const { data: { organization : { inspectionFreeLimit } = {inspectionFreeLimit: undefined} } = { } } = useQuery( GetInspectionFreeLimitDocument, { variables: { id: organizationId } });
  const ability = useContext(AbilityContext);
  const numberOfInspections = data?.craft?.inspectionGroups.reduce((acc, group) => acc + group.inspections.length, 0);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error.toString()}</p>;
  
  const canManageInspections = permissionHandler('Manage Inspections', ability);
  const groups = [...data?.craft?.inspectionGroups].sort((a, b) => a.position - b.position).map((group) => ({
    icon: GearIcon,
    color: 'blue',
    title: group.name,
    note: group.description,
    inspections: group.inspections,
    id: group.id,
    position: group.position,
  }));
  return (
    <>
      <Flyout {...flyoutProps} />
      <div className="flex flex-col w-full px-2 md:px-8">
        <div className="flex justify-between items-center bg-white p-6 border border-slate-300 rounded mb-2 shadow-blue gap-7">
          <div className="flex items-center">
            <DocumentCheckIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
            <h1 className="text-left font-bold text-brand-dark text-2xl md:text-2xl">Status</h1>
          </div>
          <div className='flex'>
            <div className="flex items-stretch gap-2">
              {canManageInspections && <Button text="Add New Group" color="navy" size="xs" icon={<PlusIcon className="h-4 w-4 mr-1" />}
                onClick={ () => updateFlyout({ title: 'Add Inspection Group', content: <New closeFlyout={closeFlyout} /> }) } /> }
            </div>
            <Menu
              as="div"
              className="flex items-center justify-center relative group transition ml-2 w-[28px] border border-white rounded hover:border-slate-200 hover:shadow-inner">
              <Menu.Button className="">
                <EllipsisVerticalIcon className="h-5 w-5 text-slate-500 cursor-pointer" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="origin-top-right absolute top-full right-0 mt-2 rounded shadow-blue-lg border border-slate-300 bg-white focus:outline-none z-[99] w-36">
                  <Menu.Item>
                    {({ active }) => (
                      <button 
                      onClick={() => updateFlyout({ title: 'Import ADS', content: <ADSFlyout closeFlyout={closeFlyout} /> })}
                      className={`${active ? 'bg-slate-100' : ''} block px-4 py-3 text-sm text-slate-700 w-full text-right rounded`}>
                        Import ADS from FAA
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      isMobileApp() ?
                      <button onClick={async () => { await navigator.share({ title:"Export Inspections from Coflyt", url: process.env.REACT_APP_API_URL+"/inspections/"+craftId, text: "Export Inspections from Coflyt"}) } } className='block px-4 py-3 text-sm text-slate-700 w-full text-right rounded'>Export</button>
                      :
                      <a
                      href={process.env.REACT_APP_API_URL+"/inspections/"+craftId}
                      className={`${active ? 'bg-slate-100' : ''} block px-4 py-3 text-sm text-slate-700 w-full text-right rounded`}
                      download>
                        Export Inspections
                      </a>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <CraftIntervals intervals={craft?.intervalsNonConsumable.filter((interval) => interval.intervalType === IntervalType.Normal).filter((interval) => {
            if( craft.loggingOption === LogType.Loghobbs && (interval.intervalSubType === IntervalSubtype.Tach || interval.intervalSubType === IntervalSubtype.Tachtwo  ) ) return false;
            if( craft.loggingOption === LogType.Logtach && interval.intervalSubType === IntervalSubtype.Hobbs ) return false;
            return true;
          }).sort((a, b) => a.name.localeCompare(b.name) ).map((interval) => ( {
            name: interval?.name,
            value: interval?.recentIntervalLog?.endValue ?? 0,
          } ))} />
        {groups.map((group, index) => {
          return <InspectionGroup disableAdding={ organizationSubtype === OrgSubtypes.FREE && inspectionFreeLimit <= numberOfInspections } key={index} group={group} updateFlyout={updateFlyout} closeFlyout={closeFlyout} />;
        })}
      </div>
    </>
  );
};

export default Status;
