import { useState } from "react";

export const maxSize = 250 * 1024 * 1024; // Max Upload File 250MB in bytes

export default function DragAndDrop({ children, field, id, className ='', refetch, showToast, enabled = true } :
    { children: React.ReactNode, field: string, id: string, className?: string, refetch: () => void, showToast: (props: any) => void, enabled?: boolean }) {

    const [isDragging, setIsDragging] = useState(false);
    const handleDrop = async (event) => {
      event.preventDefault();
      setIsDragging(false);
      const formData = new FormData();
      const files = Array.from(event.dataTransfer.files) as File[]; 
      files.forEach((file) => {
        if (file.size > maxSize) {
          alert('File(s) size exceeds the 250MB limit.');
          return;
        }
        formData.append('files', file);
      });
      formData.append(field, id );
      const res = await fetch(process.env.REACT_APP_API_URL + '/attachments/upload', { method: 'POST', body: formData });
      showToast({ title: 'File uploaded successfully'});
      if (res.ok) {
        showToast({ title: 'File uploaded successfully,', subtitle: 'May take a few moments to process' });
        refetch();
      }else{
          console.log(res);
      }
    };
    return (
      <>
        {enabled ? <div className={`${isDragging ? 'border-dashed border-2 border-slate-300' : ''} ${className}`}
            onDragOver={(e) => { e.preventDefault(); setIsDragging(true); }}
            onDragLeave={() => setIsDragging(false)}      
            onDrop={handleDrop}>
            {children}
        </div> : <div>
            {children}
        </div>}
      </>
    );
}