import SegmentControl from "components/SegmentControl/SegmentControl";
import TechnicianDashboard from "./Technician";
import ManagerDashboard from "./Manager";
import useSaveState from "hooks/useSaveState";
import { useContext } from "react";
import { AbilityContext } from "contexts/AbilityContext";
export default function Dashboard () {
    const ability = useContext(AbilityContext);
    const [activeTab, setActiveTab] = useSaveState( 'dashboardSelect', 'Manager');
    const tabs = ['Manager', 'Technician'];
    return(
        <>
            {ability.can('View', 'Manager Dashboard') ? 
            <>
                <div className="w-full md:w-1/4 mx-auto mb-2 mt-[-16px]">
                    <SegmentControl items={tabs} 
                        activeItem={ activeTab }
                        onChange={ item => setActiveTab(item) } />
                </div>
                { activeTab === 'Manager' && (<ManagerDashboard />) }
                { activeTab === 'Technician' && (<TechnicianDashboard />) }
            </> : <TechnicianDashboard />}
        </>
    )
}