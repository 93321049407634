import { useMutation, useQuery } from "@apollo/client";
import { PlusIcon } from "@heroicons/react/24/solid";
import Button from "components/Button/Button";
import Flyout, { useFlyout } from "components/Flyout/Flyout";
import { FullFormikCheckBox, FullFormikInput, FullFormikTextarea } from "components/Form/StandardForm";
import { Table } from "components/Table/Table";
import Toast, { ToastLength, useToast } from "components/Toast/Toast";
import { useSession } from "contexts";
import { Form, Formik } from "formik";
import { GetFlightPurposesDocument, GetOrganizationSettingsDocument, GetOrganizationSettingsQuery, UpdateOrganizationDocument } from "graphql/generated";
import { useEffect, useMemo, useState } from "react";
import { formatApiDate } from "utils/formatter";
import NewFlightPurpose from "./Flyouts/NewFlightPurpose";
import EditFlightPurpose from "./Flyouts/EditFlightPurpose";


export default function OrganizationSettings({ organization } : { organization: GetOrganizationSettingsQuery['organization'] }) {
    const { organizationId } = useSession();
    
    const [updateOrganization] = useMutation(UpdateOrganizationDocument, {refetchQueries:[ {
        query: GetOrganizationSettingsDocument,
        variables: { id: organizationId },
    } ] });
    const { data: { flightPurposes } = {} } = useQuery(GetFlightPurposesDocument, { variables: { where: { organizationId: { equals: organizationId } } } });
    const columns = useMemo(
        () => [
          {
            Header: 'Sort',
            accessor: 'sort', // accessor is the "key" in the data
            className: 'w-1/4',
            Cell: ({ value, row }: any) => {
              return <span className="font-bold bg-slate-100 text-brand-dark rounded px-2 py-1">{value+1}</span>;
            },
          },
          {
            Header: 'Name', 
            accessor: 'name', // accessor is the "key" in the data
            className: 'w-3/4 items-start',
          },
        ],
        []
    );
    
    const [ initialValues, setInitialValues ] = useState({
        name: '',
        billingEmail:'',
        billingAddress: '',
        allowForEditAfter: false,
        showColorTiedToCraft: false,
    });
    useEffect(() => {
        if(organization) {
            setInitialValues({
                name: organization?.name,
                billingEmail: organization?.billingEmail,
                billingAddress: organization?.billingAddress?.addressName,
                allowForEditAfter: organization?.reservationSettings?.allowForEditAfter ?? false,
                showColorTiedToCraft: organization?.reservationSettings?.showColorTiedToCraft ?? false,
            })
        }
    }, [organization]);

    const { toastProps, showToast } = useToast();
    const { flyoutProps, closeFlyout, updateFlyout } = useFlyout();
    return (
        <div className="bg-white border border-slate-300 rounded flex">
            <Toast {...toastProps} />
            <Flyout {...flyoutProps} />
            <Formik initialValues={ initialValues } enableReinitialize onSubmit={ async (values)=> { 
                await updateOrganization( { 
                    variables: { 
                        input:{
                            id: organizationId,
                            name: values.name,
                            billingEmail: values.billingEmail,
                            billingAddress: {
                                addressName: values.billingAddress,
                            },
                            reservationSettings: {
                                ...organization?.reservationSettings,
                                allowForEditAfter: values.allowForEditAfter,
                                showColorTiedToCraft: values.showColorTiedToCraft,
                            }
                        }
                    }
                } );
                showToast({ title: 'Organization Updated', type:ToastLength.Normal });
            }} >
                {({ values, isSubmitting }) => (
                    <Form className="flex flex-col w-full md:w-2/3 gap-2 p-10 rounded-l">
                        <span className="font-bold text-xs text-brand uppercase mb-6 border-b py-0.5 -mx-4 pl-4">Organization Settings</span>
                        <FullFormikInput name="name" label="Organization Name*" />
                        <span className="text-xs mt-[-10px] mb-3">If the owner is an organisation please use the legal company name</span>
                        <FullFormikInput name="billingEmail" label="Billing Email*" />
                        <FullFormikTextarea name="billingAddress" label="Billing Address" placeholder="Address" />
                        <div className="my-5">
                            <div className="w-full border-b">
                                <span className="font-bold text-xs text-brand uppercase my-6 py-0.5 -mx-4 pl-4 ">Reservation Settings</span>  
                            </div>
                            <div className="flex gap-4 mt-3">
                                <FullFormikCheckBox name="allowForEditAfter" label="Allow for edit after timeslot" isVertical />
                            </div>
                            <div className="flex gap-4 mt-3">
                                <FullFormikCheckBox name="showColorTiedToCraft" label="Show colors per craft (instead of per User)" isVertical />
                            </div>
                        </div>
                        <Button text="Update" color="navy" size="sm" type="submit" disabled={isSubmitting} className="disabled:opacity-50 mt-4" />
                        <div className="w-full border-b">
                            <span className="font-bold text-xs text-brand uppercase my-6 py-0.5 -mx-4 pl-4 ">Flight Purposes</span>                            
                        </div>
                        <Table className="border w-full rounded" data={flightPurposes ? [...flightPurposes]?.sort((a,b) => a?.sort - b?.sort) : []} columns={columns} 
                            onRowClick={(row) => updateFlyout({ title: "Edit Flight Purpose", content: <EditFlightPurpose id={row.id} closeFlyout={closeFlyout} /> })} />
                        <Button text="Add New Purpose" color="navy" size="xs" 
                            onClick={() => updateFlyout({ title: "Add New Flight Purpose", content: <NewFlightPurpose closeFlyout={closeFlyout} /> })} 
                            icon={<PlusIcon className="w-3 h-3 mr-2"/> } className="disabled:opacity-50 mt-4" />
                    </Form> )}
            </Formik>
        </div>
    )
}