import { Craft, Reservation } from "graphql/generated";
import { eventColors } from "../components/TimeSlots";
import { areDaysEqual, getDaysOfWeek, isBetweenDays } from "../utils/date"
import { randomID } from "utils/random";
import { formatApiDateTimeUTC } from "utils/formatter";

function Month({ date, events, onEventSelected, userList, colorTiedToCraft = false, crafts }
    :{ date:Date, events?:Array<any>, onEventSelected:(any)=>void, userList?:Array<string>, colorTiedToCraft?:boolean, crafts:Craft[]} ) {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var lastDayOfPriorMon = new Date(date.getFullYear(), date.getMonth(), 0);
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var firstDayOfNextMon = new Date(date.getFullYear(), date.getMonth()+1, 1);
    var days:Array<Date> = [];

    //prior month days
    for (let day = firstDay.getDay(); day > 0; day--) {
        days.push(new Date(lastDayOfPriorMon.getFullYear(),lastDayOfPriorMon.getMonth(), (lastDayOfPriorMon.getDate()-day)+1));
    }
    //current month days
    for (let day = 0; day < lastDay.getDate(); day++) {
        days.push(new Date(firstDay.getFullYear(), firstDay.getMonth(),day+1));
    }
    //next month days
    for (let day = 0; day < 7-(lastDay.getDay()+1); day++) {
        days.push(new Date(firstDayOfNextMon.getFullYear(), firstDayOfNextMon.getMonth(),day+1));
    }

    var [colorIndex, eventColorMap] = [-1, {}];
    // if colorTiedToCraft is true, then the color of the event will be tied to the craft
    if (colorTiedToCraft) {
        for (const craft of crafts) {
            colorIndex += 1;
            eventColorMap[craft.id] = eventColors[colorIndex];
        }
    }else{
        for (const userId of userList) {
            colorIndex += 1;
            eventColorMap[userId] = eventColors[colorIndex];
        }
    }
    return (
      <div className="bg-white px-5 items-center rounded-b border-slate-300 shadow-blue">
        <section className="py-5">
            {/* days of week */}
            <div className="grid grid-cols-7 text-lg rounded-t border border-brand">
                {daysOfWeek.map((value, index, arr)=>{
                    return(<div className={(index===arr.length-1?"border-transparent ":"")+"border-r border-brand w-full flex justify-center py-2"} key={index}>{value}</div>);
                })}
            </div>
            {/* days */}
            <div className="grid grid-cols-7 rounded-b border-b border-r border-l border-brand min-h-[70]">
                {days.map((day, index)=>{
                    const week = getDaysOfWeek(day);
                    return <div className={`${day.getDay()!==6?"border-r ":""} relative flex flex-col whitespace-pre-wrap border-brand min-h-[100px] text-xs r w-full ${(index<days.length-7?"border-b":"")}`} key={index}>
                        <div className="px-1">{day.getDate()}</div>
                        {/* events */}
                        {events
                        .sort((a, b) => {
                            // Convert event times to Date objects for comparison
                            const aStart = new Date(a.from);
                            const aEnd = new Date(a.to);
                            const bStart = new Date(b.from);
                            const bEnd = new Date(b.to);
                        
                            // Check if the events are multi-day (spanning different dates)
                            const aIsMultiDay = aStart.toDateString() !== aEnd.toDateString();
                            const bIsMultiDay = bStart.toDateString() !== bEnd.toDateString();
                        
                            // Prioritize multi-day events
                            if (aIsMultiDay && !bIsMultiDay) return -1;
                            if (!aIsMultiDay && bIsMultiDay) return 1; 
                            // If both are the same type, sort by start time (earliest first)
                            return aStart.getTime() - bStart.getTime();
                        })
                        .map((event, eventI)=>{
                            const eventRes = (event as Reservation);
                            const shouldShow = isBetweenDays(day, new Date(formatApiDateTimeUTC(eventRes.from)), new Date(formatApiDateTimeUTC(eventRes.to)));
                            
                            if(shouldShow){                                
                                const isStartDay = areDaysEqual(day, new Date(formatApiDateTimeUTC(eventRes.from)));
                                const isEndDay = areDaysEqual(day, new Date(formatApiDateTimeUTC(eventRes.to)));
                                const colorId = colorTiedToCraft ? eventRes.craftId : eventRes.userId;
                                return (<button onClick={()=>onEventSelected(event)} className={`${eventRes.isMaintenance ? 'bg-slate-500 text-white' : eventColorMap[colorId] ?? " bg-brand text-white "} ${!isStartDay? "text-opacity-0" : "text-[#ffffff00]"} my-1 py-2 ${isStartDay && "rounded-l-lg z-10"} ${isEndDay&&"rounded-r-lg"}`} key={eventI}>{event.name}</button>);
                            }else{
                                return null;
                            }
                        })}
                        {/* special dates i.e today */}
                        <span className="text-center bg-brand text-white bottom-0 w-full mt-auto">{areDaysEqual(day, new Date())&&"Today"}</span>
                        {!areDaysEqual(day, new Date())&&<div>&nbsp;</div>}
                    </div>
                })}
            </div>  
            
        </section>

      </div>
    );
}

export default Month;