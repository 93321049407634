import { useContext, useState } from 'react';
import { Squares2X2Icon, BriefcaseIcon, UserGroupIcon, UsersIcon, CogIcon, InboxIcon, DocumentChartBarIcon } from '@heroicons/react/24/outline';
import {
  Squares2X2Icon as Squares2X2IconActive,
  BriefcaseIcon as BriefcaseIconActive,
  UsersIcon as UsersIconSolid,
  UserGroupIcon as UserGroupIconActive,
  CogIcon as CogIconActive,
  InboxIcon as InboxIconActive,
  DocumentChartBarIcon as DocumentChartBarIconActive,
} from '@heroicons/react/24/solid';
import BarSide from '../components/BarSide';
import MobileMenu from '../components/MobileMenu';
import { GetServiceRequestBadgesDocument } from 'graphql/generated';
import { useQuery } from '@apollo/client';
import { useSession } from 'contexts';
import { AirplaneIcon } from 'assets/icons';
import AirplaneInactiveIcon from 'assets/icons/AirplaneInactiveIcon';
import BarTop from '../components/BarTop';
import { AbilityContext } from 'contexts/AbilityContext';
import MobileThumbBar from '../components/MobileThumbBar';

const Sidebar = ({
  children,
  navList,
  userNavList,
}: {
  children: React.ReactNode;
  navList?: { name: string; href: string; icon: typeof Squares2X2Icon; roles?: string[]; active?: boolean }[];
  userNavList?: { name: string; href: string }[];
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [context] = useState({});
  const { user } = useSession();
  const ability = useContext(AbilityContext);
  const isDev = process.env.REACT_APP_APP_MODE !== 'production';
  const { data: { mroServiceRequests } = {} } = useQuery(GetServiceRequestBadgesDocument, {
    skip: ability.cannot('Manage', 'Service Requests'),
    variables: { mroOrganizationId: user?.mroOrganizationId },
  });

  const navigation = navList || [
    {
      name: 'Dashboard',
      href: '/mro/dashboard',
      icon: Squares2X2Icon,
      iconActive: Squares2X2IconActive,
      active: true,
    },
    {
      name: 'Work Orders',
      href: '/mro/work-orders',
      icon: BriefcaseIcon,
      iconActive: BriefcaseIconActive,
      active: false,
      action: 'Manage',
      subject: 'Work Orders'
    },
    {
      name: 'Service Requests',
      href: 'mro-service-requests',
      icon: InboxIcon,
      iconActive: InboxIconActive,
      active: false,
      action: 'Manage',
      subject: 'Service Requests',
      badge: mroServiceRequests?.length > 0 && mroServiceRequests?.length,
    },
    { name: 'Customers', href: '/mro/customers', icon: UsersIcon, iconActive: UsersIconSolid, active: false, action: 'Manage', subject: 'Customers' },
    {
      name: 'Crafts',
      href: '/mro/crafts',
      icon: AirplaneInactiveIcon,
      iconActive: AirplaneIcon,
      active: false,
      action: 'Manage',
      subject: 'Crafts'
    },
    {
      name: 'Employees',
      href: '/mro/employees',
      icon: UserGroupIcon,
      iconActive: UserGroupIconActive,
      active: false,
      action: 'Manage',
      subject: 'Employees'
    },
    { name: 'Parts', 
      href: '/mro/parts',
      icon: CogIcon,
      iconActive: CogIconActive,
      active: false, 
      action: 'Manage',
      subject: 'Parts' },
  ];
  const mobileNav = [
    { name: 'Dashboard', href: '/mro/dashboard', icon: Squares2X2Icon, active: true },
    { name: 'Work Orders', href: '/mro/work-orders', icon: BriefcaseIcon, active: false, action: 'Manage', subject: 'Work Orders' },
    { name: 'Customers', href: '/mro/customers', icon: UsersIcon, active: false, action: 'Manage', subject: 'Customers' },
    { name: 'Employees', href: '/mro/employees', icon: UserGroupIcon, active: false, action: 'Manage', subject: 'Employees' },
    { name: 'Parts', href: '/mro/parts', icon: CogIcon, active: false, action: 'Manage', subject: 'Parts' },
  ];

  const mobileExtraNav = [
    { name: 'Service Requests', href: 'mro-service-requests', icon: InboxIcon, active: false, action: 'Manage', subject: 'Service Requests' },
    { name: 'Crafts', href: '/mro/crafts', icon: AirplaneInactiveIcon, active: false, action: 'Manage', subject: 'Crafts' },
  ]

  return (
    <>
      {/* NAV */}

      <BarTop {...{sidebarOpen, mobileMenu, setMobileMenu, context, mobileExtraNav}} altLoginUrl='/mro/auth'/>
      <BarSide
        sidebarOpen={user?.role !== 'MRO_TECHNICIAN' ? sidebarOpen : true}
        setSidebarOpen={setSidebarOpen}
        navigation={navigation}
        altLoginUrl="/mro/auth"
      />
      <MobileMenu navigation={navigation} mobileMenu={mobileMenu} />
      <MobileThumbBar mobileNav={mobileNav} />

      {/* CONTENT */}
      <div className="flex">
        <div className={`${sidebarOpen ? 'w-0 md:w-72' : 'w-12'} transition-all ease-in-out duration-300 shrink-0`}></div>
        <div className="relative w-full">{children}</div>
      </div>
    </>
  );
};

export default Sidebar;
