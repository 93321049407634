import { useMutation, useQuery } from '@apollo/client';
import {
  Severity,
  Status,
  GetCraftDocument,
  GetSquawksDocument,
  GetComponentsDocument,
  UpdateSquawkDocument,
  GetSquawkDocument,
  SquawkUpdateInput,
  DeleteSquawkDocument,
  GetCraftsDocument,
  GetMaintenanceRecordDocument,
  UpdateMaintenanceRecordDocument,
  DeleteMaintenanceRecordDocument,
  GetMaintenanceRecordSquawkDocument,
} from 'graphql/generated';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useSession } from 'contexts';
import { useEffect, useState } from 'react';
import JascSelect, { JascSystem } from 'components/JascCategories/JascSelect';
import DatePicker from 'components/DatePicker/DatePicker';
import Card from 'components/Card/Card';
import { FullFormikInput } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import FilePicker from 'components/FilePicker/FilePicker';
import Modal from 'components/Modal/Modal';
import { formatApiDate } from 'utils/formatter';
import StatusButton from 'components/StatusButton/StatusButton';

export const StyledSelect = (props) => (
  <select
    className="rounded border px-2 w-2/3 block pl-3 pr-10 py-2 text-sm border-slate-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    {...props}></select>
);

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

type SquawkFiles = File | { signedUrl?: string; name?:string; id?: string };

const EditEntries = function ({ id, closeFlyout }: { id: string; closeFlyout: (deleted?:boolean) => void} ) {
  const { craftId, organizationId } = useSession();
  const { data: { maintenanceRecord } = {}, error } = useQuery( GetMaintenanceRecordSquawkDocument, { variables: { id }, pollInterval: 0 });
  
  const [ updateMaintenanceRecord ] = useMutation( UpdateMaintenanceRecordDocument, { refetchQueries: [ { query: GetSquawkDocument, variables: { id: maintenanceRecord?.squawks?.[0]?.id } } ] } );
  const [ deleteMaintenanceRecord ] = useMutation( DeleteMaintenanceRecordDocument, { variables: { id }, 
      refetchQueries: [ { query: GetSquawkDocument, variables: { id: maintenanceRecord?.squawks?.[0]?.id } } ],
      onCompleted: () => closeFlyout() } ); 
  const [ modal, setModal ] = useState(false);
  const [files, setFiles] = useState<SquawkFiles[]>([]);

  const [initialValues, setInitialValues] = useState({
    status: 'IN_PROGRESS',
    severity: 'WARNING',
    description: '',
  });
  console.log(id, maintenanceRecord);
  useEffect(() => {
    if (maintenanceRecord) {
      setInitialValues({
        ...initialValues,
        description: maintenanceRecord?.description,
      });
    }
  }, [maintenanceRecord]);
  
  const handleSubmit = async (values: typeof initialValues, { setSubmitting }: FormikHelpers<typeof initialValues>) => {
    //await updateSquawk({ variables: { input: squawkInput } });
    closeFlyout();
  };
  const severityStatus = maintenanceRecord?.severityStatus ?? {};
  return (
    <>
      { modal && <Modal message={'You are about to delete the Squwak Update.'} accept={deleteMaintenanceRecord} cancel={setModal} /> }
      <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <Card className='m-4'>
              <dl>
                {maintenanceRecord?.completionDate && <div className="flex flex-col">
                  <span className="font-bold text-lg">Update At:</span>
                  <span className="text-md">{formatApiDate(maintenanceRecord?.completionDate)}</span>
                </div>}
                <div className="flex flex-col">
                  <span className="font-bold text-lg">Description:</span>
                  <span className="text-md">{maintenanceRecord?.description}</span>
                </div>
                <div className="flex flex-col">
                  <span className="font-bold text-lg">Perfromed By:</span>
                  <span className="text-md">{(maintenanceRecord?.performedBy?.firstName ?? '') + " " + (maintenanceRecord?.performedBy?.lastName ?? '')}</span>
                </div>
                <div className="flex flex-col">
                  { severityStatus?.status &&
                    <div className="flex flex-col">
                      <span className="font-bold text-lg">Status Update:</span>
                      <StatusButton context="squawk" text={severityStatus?.status} readOnly={true} className="w-12" />
                    </div>
                  } 
                  { severityStatus?.severity && 
                  <div className="flex flex-col">
                    <span className="font-bold text-lg">Severity Update:</span>
                    <StatusButton context="squawk" text={severityStatus?.severity} readOnly={true} className="w-12" />
                  </div> }
                </div>
              </dl>
            </Card>
            <div className="px-4 mb-10 flex items-center justify-end gap-10">
              <Button
                type="button"
                size='xs'
                color='red'
                onClick={() => setModal(true)}
                disabled={isSubmitting}
                text='Delete' />
              {/* <Button
                type="submit"
                size='xs'
                disabled={isSubmitting}
                text='Update Squawk' /> */}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditEntries;
