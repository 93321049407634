import { useMutation } from "@apollo/client";
import Button from "components/Button/Button";
import { FullFormikInput } from "components/Form/StandardForm";
import { Form, Formik } from "formik";
import { GetMroCustomerQuery, UpdateMroCustomerDocument } from "graphql/generated";
import * as Yup from 'yup';

const EditCustomerSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone: Yup.string(),
});

export default function CustomerEdit({ mroCustomer, closeFlyout } : { mroCustomer: GetMroCustomerQuery['mroCustomer'], closeFlyout: () => void }) {
    const [ updateCustomer ] = useMutation(UpdateMroCustomerDocument, { 
        refetchQueries: ['GetMroCustomerDocument', 'GetMroCustomersDocument'],
    });
    const initialValues = {
        name: mroCustomer?.name,
        email: mroCustomer?.email,
        phone: mroCustomer?.phoneNumbers?.primary,

    };
    return (
        <div className="p-4">
            <Formik initialValues={initialValues} validationSchema={EditCustomerSchema} onSubmit={async (values) =>{
                let phoneNumbers = {...mroCustomer?.phoneNumbers};
                if(phoneNumbers && phoneNumbers?.primary){
                    phoneNumbers.primary = values.phone;
                }else{
                    phoneNumbers = { primary: values.phone };
                }
                await updateCustomer({ variables: { input:{ id: mroCustomer.id, 
                    name: values.name, 
                    email: values.email, 
                    phoneNumbers
                 } } });
                closeFlyout();
            }}>
                <Form>
                    <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
                        <FullFormikInput name="name" label="Name" />
                        <FullFormikInput name="email" label="Email" />
                        <FullFormikInput name="phone" label="Phone Number" />
                    </div>
                    <div className="flex justify-end items-center mt-4">
                        <Button text="Save" color="navy" size="sm" type="submit" />
                    </div>
                </Form>
            </Formik>
        </div>
    );
}