import React, { createContext, useContext, useState, ReactNode } from 'react';

type RefetchContextType = {
  refetchList?: () => void;
  setRefetchList: (refetchFn: () => void) => void;
};

const RefetchContext = createContext<RefetchContextType>({
  setRefetchList: () => {},
});

export const useRefetchContext = () => useContext(RefetchContext);

export const RefetchProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [refetchList, setRefetchList] = useState<(() => void) | undefined>(undefined);

  return (
    <RefetchContext.Provider value={{ refetchList, setRefetchList }}>
      {children}
    </RefetchContext.Provider>
  );
};
