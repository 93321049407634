import React, { useState, useEffect } from 'react';
import { Table } from 'components/Table/Table';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@apollo/client';
import { GetMroCraftsDocument } from 'graphql/generated';
import { useSession } from 'contexts';
import { useNavigate } from 'react-router-dom';
import { AirplaneIcon, PlusIcon } from 'assets/icons';
import Tabs from 'components/Tabs/Tabs';
import useSaveState from 'hooks/useSaveState';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import NewReport from './New/index';
import { PaperClipIcon } from '@heroicons/react/24/solid';


/*
Reports List:

Part Inverntory
Time Logs
Work Orders profitability
Amount billed and not billed for work orders

*/
const MroReports = function (props) {

  const [query, setQuery] = useState<string>();
  const { user } = useSession();
  const { updateFlyout, closeFlyout, flyoutProps } = useFlyout();
  return (
    <>
      <Flyout { ...flyoutProps } />
      <div className="flex justify-between items-center bg-white p-6 border border-slate-300 rounded mb-2 shadow-blue">
        <div className="flex items-center">
          <PaperClipIcon />
          <h1 className="text-left font-bold text-brand-dark text-2xl md:text-2xl">Reports</h1>
        </div>
        <div className="flex items-stretch gap-2">
          <div className="relative flex items-center">
            <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
            <input
              className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-7"
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <button
            className="flex items-center bg-brand text-white border border-brand text-sm rounded font-semibold py-1.5 px-4"
            onClick={() => updateFlyout({ title: 'Add Report', content: <NewReport closeFlyout={closeFlyout} /> })}
            >
            <PlusIcon className="h-3 w-3 mr-1" />
            Add New
          </button>
        </div>
      </div>
      <div className="p-6 bg-white border rounded border-slate-300 w-full max-w-[calc(78rem-2px)] shadow-blue">
        <div className="flex justify-between items-center border-y border-slate-200 pt-1 bg-slate-50 w-[calc(100%+3rem)] -ml-6 px-8 -mt-2 mb-3">
        </div>
      </div>
    </>
  );
};

export default MroReports;
