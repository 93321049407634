import { useMutation, useQuery } from '@apollo/client';
import { PencilIcon } from '@heroicons/react/24/solid';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import { FormLabel, FullFormikTextarea } from 'components/Form/StandardForm';
import { useSession } from 'contexts';
import { Field, Form, Formik } from 'formik';
import { GetSquawkDocument, UpdateSquawkDocument, Status, Severity, GetCraftDocument, GetCraftsDocument } from 'graphql/generated';
import { useContext, useMemo, useState } from 'react';
import { formatApiDate } from 'utils/formatter';
import Edit, { StyledSelect, toTitleCase } from '../Edit';
import { Table } from 'components/Table/Table';
import Button from 'components/Button/Button';
import StatusButton from 'components/StatusButton/StatusButton';
import { webFormat } from 'utils/statuses';
import Card from 'components/Card/Card';
import DocumentViewer from 'components/DocumentViewer/DocumentViewer';
import isMobileApp from 'utils/isMobileApp';
import { AbilityContext } from 'contexts/AbilityContext';
import { permissionHandler } from 'utils/PermissionHandler';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';
import EditEntries from '../EditEntries';

const View = function ({ id, closeFlyout } : { id : string, closeFlyout: (deleted?:boolean) => void }) {
    const { data: { squawk } = {}, error, refetch }  = useQuery(GetSquawkDocument, { variables: { id }, pollInterval: 0 });
    const { user, organizationId, craftId} = useSession();
    const [showDocument, setShowDocument ] = useState(null);
    const { flyoutProps, closeFlyout: closeEdit, updateFlyout } = useFlyout(); 
    const ability = useContext(AbilityContext);
    const { toastProps, showToast } = useToast();
    const canManageSquawks = permissionHandler('Manage Squawks', ability);
    const [ updateSquawk ] = useMutation( UpdateSquawkDocument, { refetchQueries: [ 
      { query: GetSquawkDocument, variables: { id } },
      { query: GetCraftDocument, variables: { craftId } },
      { query: GetCraftsDocument, variables: { organizationId } },
    ], onCompleted: () => { 
      showToast({ title: "New Update Added", type: ToastLength.Normal });
    } });
    const initialValues = {
      severity: squawk?.severity,
      status: squawk?.status,
      description: '',
    };
    const columns = useMemo(
    () => [
        {
            Header: 'Date Performed',
            accessor: 'completionDate', // accessor is the "key" in the data
            className: 'w-2/6',
            Cell: ({ value, row }: any) => {
                return formatApiDate(value);
            },
        },
        {
          Header: 'Performed By',
          accessor: 'performedBy', // accessor is the "key" in the data
          className: 'w-2/6',
          Cell: ({ value, row }: any) => {
              return value?.firstName+' '+value?.lastName;
          },
        },
        {
            Header: 'Notes',
            accessor: (row) => ({
              description: row.description,
              severityStatus: row.severityStatus,
            }),
            className: 'w-2/6',
            Cell: ({ value, row }: any) => {              
              return <div className=''>
                {value?.severityStatus?.severity && (
                  <p>Severity Changed to <span className='text-yellow-500 font-bold'>{webFormat(value.severityStatus.severity)}</span></p>
                )}
                {value?.severityStatus?.status && (
                  <p>Status Changed to <span className='text-brand-electric font-bold'>{webFormat(value.severityStatus.status)}</span></p>
                )}
                {value.description && <p>{value.description}</p>}
              </div>;
              
              ;
            },
        },
    ], [squawk?.maintenanceRecords] );
    return (
        <>
          <Toast {...toastProps} position='top'  /> 
          {showDocument && <DocumentViewer uri={showDocument?.uri} fileType={showDocument?.fileType} close={()=>setShowDocument(null)} />}
          <Flyout {...flyoutProps} />
          <div className="flex flex-col w-full items-center justify-center p-4">
            <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={async (values, actions) => {
              actions.resetForm();
              const updateData = { ...(squawk?.status !== values.status && { status: values.status }), ...(squawk?.severity !== values.severity && { severity: values.severity }) };
              await updateSquawk({
                variables: {
                  input: {
                    id,
                    status: values.status as Status,
                    severity: values.severity as Severity,
                    craft: { connect: { id: craftId } },
                    maintenanceRecords: { 
                      create: [ {
                        performedBy: { connect: { id: user.userOrganizationProfileId } },
                        name: 'Squawk Update '+user.firstName+' '+user.lastName,
                        completionDate: new Date(),
                        description: values.description,
                        severityStatus: updateData,
                      } ]
                    }
                  },
                },
              });
            }}>
              {({ isSubmitting, setFieldValue, values }) => (
                <Form className="w-full border rounded p-8 shadow bg-white">
                    <div className="flex items-center justify-between mt-[-10px] mb-3">
                      <h1 className="font-bold text-lg md:text-xl">{squawk?.title}</h1>
                      {canManageSquawks && <PencilIcon className='h-4 w-4 cursor-pointer' onClick={()=>updateFlyout({ title: 'Edit Squawk', content: <Edit id={id} closeFlyout={(didDelete)=>{
                        closeEdit();
                        if(didDelete){
                          closeFlyout(true);
                        }else{
                          refetch();
                        }
                      }}/> })} /> }
                    </div>
                    <h2>Description: {squawk?.description}</h2>
                    <h3 className='text-sm'>JASC: {squawk?.systemsAffected.map((system, index) => 
                      `${system.code}-${webFormat(system.name)}`
                    ).join(', ')}</h3>
                    <div className='flex justify-between flex-wrap'>
                      <div className='flex gap-3 items-center w-1/3 my-4 flex-wrap'>
                        <h3 className='font-bold'>Status:</h3>
                        <StatusButton context="squawk" text={squawk?.status} readOnly={true} className="w-12" />
                      </div>
                      <div className='flex gap-3 items-center w-1/3 my-4 flex-wrap'>
                        <h3 className='font-bold'>Severity:</h3>
                        <StatusButton context="severity" text={squawk?.severity} readOnly={true} className="w-12" />
                      </div>
                    </div>
                    {canManageSquawks && <>
                      <FullFormikTextarea name="description" label="Notes" autoComplete="off" className='flex flex-col w-full mb-2' />
                      <div className="p-4 flex items-center justify-between">
                        <dt className="text-sm font-medium text-slate-500 w-1/3">Status</dt>
                        <Field as={StyledSelect} name="status">
                          {Object.keys(Status).map((key) => (
                            <option key={key} value={Status[key]}>
                              {toTitleCase(Status[key].replace('_', ' '))}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="p-4 flex items-center justify-between">
                        <dt className="text-sm font-medium text-slate-500 w-1/3">Severity</dt>
                        <Field as={StyledSelect} name="severity">
                          {Object.keys(Severity).map((key) => (
                            <option key={key} value={Severity[key]}>
                              {toTitleCase(Severity[key].replace('_', ' '))}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="flex items-center justify-end mt-2">
                          <Button text="Save" color="navy" type='submit' size="xs" />
                      </div>
                    </> }
                </Form>
              )}
            </Formik>
            <Card className='w-full' title="Previous Entries">
                <Table className="border w-full" 
                columns={columns} 
                data={squawk?.maintenanceRecords}
                onRowClick={(row) => {
                  updateFlyout({ title: 'Maintenance Record', content: <EditEntries id={row.id} closeFlyout={ ()=> closeEdit()} /> });
                }}
                 />
            </Card>
            {squawk?.attachments?.length > 0 && <Card className='w-full' title="Attachments">
                <div className='flex flex-wrap gap-4'>
                  {squawk?.attachments?.map((file, index) => (
                    <div className='flex flex-col gap-2' key={index}>
                      {isMobileApp() ? 
                        <span className='text-blue-500 underline' onClick={() => setShowDocument({uri: file?.signedUrl, fileType: file?.fileType })}>{file.name}</span>
                      :
                        <a href={file.signedUrl} target="_blank" className='text-blue-500 underline'>{file.name}</a>
                      }
                    </div>
                  ))}
                </div>
            </Card> }
          </div>
        </> 
  );
};

export default View;
