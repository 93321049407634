import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/24/solid";
import Flyout, { useFlyout } from "components/Flyout/Flyout";
import { useSession } from "contexts";
import ScreenShot from 'assets/images/announcement.jpg';
import { useContext, useState } from "react";
import { OrgSubtypes } from "utils/orgTypes";
import New from "./New/index";
import { useQuery } from "@apollo/client";
import { GetAnnouncementsDocument } from "graphql/generated";
import AnnouncementEntry from "./Components/AnnouncementEntry";
import { AbilityContext } from "contexts/AbilityContext";
import { permissionHandler } from "utils/PermissionHandler";
import Card from "components/Card/Card";
import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";

const Announcements = function() {
  const { flyoutProps, closeFlyout, updateFlyout } = useFlyout();
  const { organizationSubtype, organizationId, user } = useSession();
  const ability = useContext(AbilityContext);
  const navigate = useNavigate();
  const [ query, setQuery ] = useState('');
  const { data: { announcements } = {}, refetch } = useQuery(GetAnnouncementsDocument, {
    variables: {
      where: {
        organizationId: { equals: organizationId },
        OR: [
          { active: { equals: true } },
          { active: { equals: false }, creatorId: { equals: user.userOrganizationProfileId } }
        ]
      }
    },
    fetchPolicy: 'network-only'
  });
  return(
    <>
      <Flyout {...flyoutProps} />
      <div className="flex flex-col">
        {/* Title */}
        <div className="flex flex-col md:flex-row justify-between items-center bg-white p-6 border border-slate-300 rounded mb-2 shadow-blue">
          <div className="flex items-center mb-5 md:mb-0">
            <h1 className="text-left font-bold text-brand-dark text-2xl md:text-2xl">Announcements</h1>
          </div>
          { organizationSubtype === OrgSubtypes.MULTI && <div className="flex justify-end gap-2 flex-wrap">
            <div className="relative flex items-center">
              <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
              <input
                className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-7"
                placeholder="Search"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            {permissionHandler('Create Announcement', ability) &&
            <button
              onClick={()=> updateFlyout({ title: 'New Announcements', content: <New closeFlyout={()=>{closeFlyout();refetch();}} /> })}
              className="flex items-center bg-brand text-white border border-brand text-sm text-wrap rounded font-semibold py-1.5 px-4">
              <PlusIcon className="h-3 w-3 mr-1" />
              Add New Announcement
            </button> }
          </div> }
        </div>
        {/* Content */}
        { organizationSubtype === OrgSubtypes.MULTI ? <>
          { announcements?.length !== 0 ? <div className="grid md:grid-cols-2 gap-4 grid-cols-1">
            { [...(announcements ??[])]?.sort( (a, b) => Number(b.pinned) - Number(a.pinned) )?.map((announcement) => (
              <AnnouncementEntry key={announcement.id} {...announcement} updatedAt={announcement.updatedAt} />
            ))}
          </div> :
            <div className="flex flex-col items-center justify-center p-10 bg-white border border-slate-300 rounded shadow-blue">
              <h1 className="text-xl text-slate-500">No Announcements</h1>
              <h2 className="text-slate-500">Create a new announcement to get started.</h2>
            </div>
          }
        </> : <>
          <Card>
              <h1 className="text-center text-3xl font-bold text-brand-dark">Try Our Multi-Pilot Plan</h1>
              <h2 className="text-center text-xl font-semibold text-slate-400">Sending Announcements to all your users is available on our Multi Pilot plan</h2>
              <div className='flex flex-col justify-center my-8 gap-7'>
                <img className="max-h-[26rem] mx-auto" src={ScreenShot} alt="" />
                <Button text="UPGRADE NOW" color="navy" size="sm" onClick={ () => navigate('/account/settings?settingsTab=Billing') } />
              </div>
            </Card>
        </>}
      </div>
    </>
  );
}

export default Announcements;