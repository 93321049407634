import Logo from 'assets/icons/logo_navbar_w.svg';
import Button from 'components/Button/Button';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useSession } from 'contexts';
import { Link, useLocation } from 'react-router-dom';
import { OrgSubtypes, OrgTypes } from 'utils/orgTypes';
import { useEffect } from 'react';
import isMobileApp from 'utils/isMobileApp';
import { FullFormikCheckBox } from 'components/Form/StandardForm';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GetOrgSubtypeDocument } from 'graphql/generated';

const loginValues = {
  email: '',
  password: '',
  showPassword: false,
};

const SignIn = (props) => {

  const { login, setOrganizationId, setOrganizationType, user, organizationId, logout } = useSession();
  const { loginType, email, password } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [ getOrgSubType ] = useLazyQuery(GetOrgSubtypeDocument);
  const handleLogin = async (orgId) => {    
    setOrganizationType( OrgTypes.PART91 );
    setOrganizationId(orgId);
    navigate('/crafts');
  };

  // Impersonate login
  useEffect(() => {
    if (loginType === 'impersonate') {
      login( decodeURIComponent(email), decodeURIComponent(password), true).then(async (res) => {
        handleLogin(res.organizationId);
      });
    }
    if(loginType === "error"){
      alert("Error: Sign In failed - please try again");
      const newUrl =  window.location.href.replace('/auth/error', '/auth');
      window.history.replaceState({}, document.title, newUrl);
    }
    if(loginType === "error-sign-failed"){
      if (window.confirm("Error: Unable to sign in - redirecting to legacy sign in page")) {
        window.location.href = 'https://app.coflyt.com/login';
      }
    }
    if(loginType === "user-migrated"){
      alert("You have been migrated to the our new system. Please login again with your email and password");
      const newUrl =  window.location.href.replace('/auth/user-migrated', '/auth');
      window.history.replaceState({}, document.title, newUrl);
    }
  }, [loginType, email, password]);

  useEffect(() => {
    if (user && organizationId) {
      handleLogin(organizationId);
    }
  }, [user]);
  
  const isMroLogin = location.pathname.includes('/mro/auth');
  return (
    <div className="flex flex-col justify-center items-center w-[23rem] md:border-x border-brand-pale border-dashed">
      <div className="flex justify-center w-full min-w-[90vw] md:border-y border-brand-pale border-dashed">
        <div className="w-full rounded mdborder border-slate-300 hover-shadow border-b-4 min-h-[90vh] md:min-h-0 md:w-96 pt-8 md:pt-5 bg-white">
          <Formik
            initialValues={loginValues}
            onSubmit={async (values: typeof loginValues) => {
              try {
                const userLogin = await login(values.email, values.password);
                if(isMroLogin){ // MRO LOGIN
                  if(userLogin.mroEmployeeProfileId){
                    setOrganizationType( OrgTypes.PART145 );
                    navigate('/mro/dashboard');
                  }else{
                    alert("Error: Employee is not tied to an organization");
                  }
                } else { // STANDARD LOGIN
                  if(userLogin?.organizationId){
                    handleLogin(userLogin.organizationId);
                  }else{
                    navigate('/sign-up');
                  }
                }
              } catch (error: any) {
                alert(error.message);
              }
            }}>
            {({ values }) => (
              <Form className="">
                <div
                  id="ribbon"
                  className="rounded-tl text-white text-center text-2xl font-bold pl-10 pr-8 -ml-2 bg-brand relative border-b-2 border-r-1 border-t border-t-brand border-[#162551] rounded-r w-full">
                  <img alt="logo" className="w-32 py-2" src={Logo} />
                </div>
                <div className="flex flex-col p-10">
                  <div className="flex flex-col mb-4">
                    <span className="text-2xl font-bold text-brand">Log in to your account</span>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-sm font-medium text-brand">Email</label>
                    <Field
                      name="email"
                      type="email"
                      className="rounded border-slate-300 bg-slate-50 h-12 md:h-10 mb-3 px-2 text-brand focus:shadow-inner focus:bg-white placeholder:text-slate-400 placeholder:text-sm"></Field>
                    <ErrorMessage name="email" component="div" className="text-red-500 text-xs italic" />
                  </div>
                  <div className="flex flex-col pb-2 px-2 -mx-2">
                    <label className="text-sm font-medium text-brand">Password</label>
                    <Field
                      name="password"
                      type={ !values.showPassword && "password"}
                      className="rounded border-slate-300 bg-slate-50 h-12 md:h-10 mb-3 px-2 text-brand focus:shadow-inner focus:bg-white placeholder:text-slate-400 placeholder:text-sm"></Field>
                    <ErrorMessage name="password" component="div" className="text-red-500 text-xs italic" />
                  </div>
                  <div className="flex flex-col self-start -mt-3">
                    <FullFormikCheckBox name="showPassword" isVertical={true} label='Show Password' />
                  </div>
                  <div className="flex flex-col justify-start border-b border-brand-pale border-dashed pb-2 w-full" />
                  <div className="flex items-center justify-between mt-2">
                    <div className="flex flex-col gap-6 pt-10">
                      <Link to={isMroLogin ? '/auth/forgot-password/mro' : '/auth/forgot-password'} className="underline text-brand font-semibold text-sm">
                        Forgot password?
                      </Link>
                      <Link to={'https://app.coflyt.com/login'} className="underline text-brand font-semibold text-sm">
                        Legacy Login
                      </Link>
                        
                      { isMroLogin ?  (
                          <Link to={'/auth'} className="underline text-brand font-semibold text-sm">
                            Not an Employee?
                          </Link>
                        ) : (
                          <Link to={'/mro/auth'} className="underline text-brand font-semibold text-sm">
                            Are you an Employee?
                          </Link>
                        )
                      }
                    </div>
                    <Button text="Login" color="navy" size="sm" type="submit" />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      { !isMroLogin && <div className='flex justify-center items-center mt-3 gap-3'>
        <span className="text-brand mb-2">Don't have an account?</span>
        {/* <Button text="Sign Up" color="blue" size="sm" onClick={() => window.location.href ='https://app.coflyt.com/signup'} /> */}
        <Button text="Sign Up" color="blue" size="sm" onClick={() => navigate('/sign-up')} />
      </div> }
    </div>
  );
};


export default SignIn;
