import { Reservation } from "graphql/generated";
import { formatCalendarMonthDay } from "utils/formatter";
import TimeSlots, { TimeSlotsType } from "../components/TimeSlots";
import { getDaysOfWeek } from "../utils/date";
import { useMemo } from 'react';

export default function Week({ date, events = [], onEventSelected, userList, colorTiedToCraft = false, crafts }
    :{date: Date, events?: Array<Object>, onEventSelected: (any) => void, userList?: Array<string>, colorTiedToCraft?: boolean, crafts: Array<any>}) {
    // Constants
    const columns = ["", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const intervals = 48; // Number of half-hour slots

    // Memoized calculation of days of the week
    const daysOfWeek = useMemo(() => getDaysOfWeek(date), [date]);

    // Generate time slots for the week using useMemo to avoid unnecessary recalculations
    const timeSlots = useMemo(() => {
        return columns.map((_, index) => {
            const slots: Array<Date> = [];
            let baseTime = index === 0 ? daysOfWeek[0].getTime() : daysOfWeek[index - 1].getTime();
            baseTime -= 30 * 60000; // Offset by -30 mins for start

            for (let slot = 0; slot < intervals; slot++) {
                slots.push(new Date(baseTime + slot * 30 * 60000)); // Increment by 30 mins
            }
            return slots;
        });
    }, [daysOfWeek]);

    return (
        <div className="py-5 bg-white px-5 overflow-x-auto w-full items-center grid grid-col-7 rounded-b border-slate-300 shadow-blue">
            {/* Days of the week header */}
            <div className="flex min-w-max text-md rounded-t border border-brand">
                {columns.map((value, index) => (
                    <div
                        key={index}
                        className={`${
                            index === columns.length - 1 ? "border-transparent" : "border-r border-brand"
                        } w-full flex justify-center py-2 ${
                            index === 0 ? "flex-1" : "min-w-[100px] flex-[1.7]"
                        }`}
                    >
                        {value && `${value} ${formatCalendarMonthDay(daysOfWeek[index - 1])}`}
                    </div>
                ))}
            </div>
            {/* Time slots for the week */}
            <TimeSlots data={timeSlots} events={events} onClick={onEventSelected} type={TimeSlotsType.Week} userList={userList} colorTiedToCraft={colorTiedToCraft} crafts={crafts} />
        </div>
    );
}

