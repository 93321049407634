import { FullFormikInput } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import { Form, Formik } from 'formik';
import { GetMroComponentsDocument, GetMroWorkOrderDocument, UpdateMroComponentDocument } from 'graphql/generated';
import { useMutation } from '@apollo/client';

export default function EditComponent({
  workOrderId,
  mroCraftId,
  component,
  closeFlyout,
}: {
  workOrderId?: string;
  mroCraftId?: string;
  closeFlyout?: () => void;
  component: any;
}) {
  const [updateComponent] = useMutation(UpdateMroComponentDocument, {
    ...(workOrderId && { refetchQueries: [{ query: GetMroWorkOrderDocument, variables: { id: workOrderId } }] }),
    ...(mroCraftId && { refetchQueries: [{ query: GetMroComponentsDocument, variables: { mroCraftId } }] }),
  });
  const initialValues = {
    serialNumber: component.serialNumber ?? '',
    name: component.name ?? '',
    make: component.make ?? '',
    model: component.model ?? '',
    year: component.year ?? '',
  };
  const handleSubmit = function (values: typeof initialValues) {
    updateComponent({
      variables: {
        input: {
          id: component.id,
          serialNumber: values.serialNumber,
          name: values.name,
          make: values.make,
          model: values.model,
          year: parseInt(values.year, 10),
        },
      },
    });
    closeFlyout();
  };

  return (
    <>
      <div className="p-4">
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <div className="flex flex-col w-full border rounded shadow bg-white py-10">
                <div className="flex px-10 gap-2">
                  <FullFormikInput name="name" label="Name" className="w-full" />
                </div>
                <div className="flex px-10 gap-2">
                  <FullFormikInput name="make" label="Make" className="w-1/2" />
                  <FullFormikInput name="model" label="Model" className="w-1/2" />
                </div>
                <div className="flex px-10 gap-2">
                  <FullFormikInput name="year" label="Year" className="w-1/2" />
                  <FullFormikInput name="serialNumber" label="Serial No." className="w-1/2" />
                </div>
              </div>
              <div className="flex justify-end items-center mt-4">
                <Button text="Save" color="navy" size="xs" type="submit" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
