
import { ArrowUpTrayIcon, ArrowDownTrayIcon, BriefcaseIcon } from '@heroicons/react/24/solid';
import { useRef, useState } from 'react';
import { useSession } from 'contexts';
import { GetDocumentsDocument } from 'graphql/generated';
import { useQuery } from '@apollo/client';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import { DocumentsView } from 'components/Documents/Documents';
import Card from 'components/Card/Card';
import Paywall from 'components/Paywall/Paywall';
import { OrgSubtypes } from 'utils/orgTypes';
import DragAndDrop, { maxSize } from 'views/Part91/Aircraft/Documents/Components/DragAndDrop';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';

export default function OrganizationDocuments () {
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const { user, organizationId, organizationSubtype } = useSession();
  const { data : { documents = [] } = {}, refetch } = useQuery( GetDocumentsDocument, { variables: { where: { organizationId: { equals: organizationId } } } } );
  const { showToast, toastProps } = useToast();
  const uploadButton = useRef(null);

  const uploadDocument = async(event) => {
    const formData = new FormData();
    const files = Array.from(event.target.files) as File[];
    files.forEach((file) => {
      if (file.size > maxSize) {
        alert('File(s) size exceeds the 250MB limit.');
        return;
      }
      formData.append('files', file);
    });
    uploadButton.current.value = '';
    formData.append('organizationId', organizationId );
    const res = await fetch(process.env.REACT_APP_API_URL + '/attachments/upload', { method: 'POST', body: formData });
    if (res.ok) {
      showToast({ title: 'File uploaded successfully', type: ToastLength.Normal});
      refetch();
    }else{
      console.log(res);
    }
  };

  return (
    <>
      <Toast { ...toastProps } position='top' />
      <Flyout { ...flyoutProps } />
      {organizationSubtype === OrgSubtypes.FREE ? <Paywall /> :
        <DragAndDrop field='organizationId' id={organizationId} refetch={refetch} showToast={showToast}>
          <Card title='Organization Documents' subtitle='Documents for the Organization'>
            {/* Header */}
            <div className="flex justify-between items-center p-6">
              <div className="flex items-stretch gap-2">
                <button 
                  onClick={() => uploadButton.current.click()}
                  className="border bg-white p-1 px-2 rounded flex items-center text-sm font-semibold">
                  <ArrowUpTrayIcon className="h-3 w-3 mr-1 stroke-[0.15rem]" />
                  Upload Documents
                </button>
                <input
                  type="file"
                  ref={uploadButton}
                  onChange={uploadDocument}
                  style={{ display: 'none' }}
                  accept="image/jpeg, image/png, image/gif, application/pdf" />
              </div>
            </div>
            {/* Body */}
            <DocumentsView {...{ closeFlyout, updateFlyout, data: (documents as any) }}  />
          </Card>
        </DragAndDrop>
      }
    </> 
  );
};

