import React, { useReducer } from 'react';

/*

//usage

import Toast, {useToast} from 'components/Toast/Toast';
const {showToast, toastProps, isShowing} = useToast();

showToast({type:ToastLength.Long, title:"Sample Toast", subtitle:"Subtitle here" })


return(
    <Toast {...toastProps} />
)

*/
export enum ToastLength {
  Hide,
  Short,
  Normal,
  Long,
}

export interface ShowToastState {
  isShowing: boolean;
  length: number;
  title: string;
  subtitle: string;
}

export interface ToastProps {
  state: ShowToastState;
  dispatch: React.Dispatch<any>;
  position?: 'top' | 'bottom';
}

export interface ShowToastReturn {
  isShowing: boolean;
  showToast: React.Dispatch<{ type: ToastLength; title?: string; subtitle?: string }>;
  toastProps: ToastProps;
}

export interface ShowToastProps {
  title?: string;
  subtitle?: string;
  type?: ToastLength;
}

export function useToast(): ShowToastReturn {
  const initialToastState = {
    isShowing: false,
    length: 0,
    title: '',
    subtitle: '',
  };
  const [state, dispatch] = useReducer(showToast, initialToastState as ShowToastState);
  function showToast(passState, action: ShowToastProps) {
    var toastState = { isShowing: true, title: action.title ?? '', subtitle: action.subtitle ?? '', length: 0 };

    switch (action.type) {
      case ToastLength.Short:
        toastState = { ...toastState, length: 3000 };
        break;
      case ToastLength.Normal:
        toastState = { ...toastState, length: 5000 };
        break;
      case ToastLength.Long:
        toastState = { ...toastState, length: 7000 };
        break;
      case ToastLength.Hide:
        return { isShowing: false, length: 0, subtitle: passState.subtitle, title: passState.title };
      default:
        toastState = { ...toastState, length: 5000 };
    }
    setTimeout(() => dispatch({ type: ToastLength.Hide }), toastState.length);
    return toastState;
  }

  return {
    isShowing: state.isShowing,
    showToast: dispatch,
    toastProps: { state, dispatch },
  };
}

function Toast({ state, dispatch, position }: ToastProps) {
  return (
    <div
      className={`z-30 flex items-center fixed m-auto ${position === "top" ? "top-44 left-0 right-0" : "bottom-[100px] left-0 right-0" } w-full max-w-xs text-gray-500 bg-white rounded-lg shadow transition-[opacity_1s,height_3s] duration-500 ease-in-out ${
        state.isShowing ? 'opacity-100 p-4' : 'opacity-0 h-0 invisible p-0'
      }`}>
      <div className="">
        <div className="ml-3 text-sm font-bold">{state.title}</div>
        <div className="ml-3 text-xs font-normal">{state.subtitle}</div>
      </div>

      <button
        type='button'
        onClick={() => dispatch({ type: ToastLength.Hide })}
        className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8"
        data-dismiss-target="#toast-default"
        aria-label="Close">
        <span className="sr-only">Close</span>
        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"></path>
        </svg>
      </button>
    </div>
  );
}

export default Toast;
