import { useLazyQuery } from "@apollo/client";
import { Combobox } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { GetUserOrganizationProfilesDocument } from "graphql/generated";
import { useEffect, useState } from "react";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

export type Pilot = {
    id?: string;
    name: string;
};


export default function PilotSelect(
{   pilot, 
    setValue 
} : {
    pilot: Pilot;
    setValue: (value: string) => void;
}) {
    const [ userName, setUserName ] = useState(pilot?.name ?? '');
    const [search, { data: { userOrganizationProfiles } = { userOrganizationProfiles: [] } }] = useLazyQuery(GetUserOrganizationProfilesDocument);
    useEffect(() => {
        const timeOutId = setTimeout(
            () =>
            search({
                variables: {
                    where: {
                        OR: [
                            {
                                user: {
                                    is:{
                                        firstName: {
                                            contains: userName?.trim().replaceAll(' ', ' & '),
                                        }
                                    }
                                },
                            },
                            {
                                user: {
                                    is:{
                                        lastName: {
                                            contains: userName?.trim().replaceAll(' ', ' & '),
                                        }
                                    }
                                },
                            },
                            {
                                user: {
                                    is:{
                                        email: {
                                            contains: userName.trim().replaceAll(' ', ' & '),
                                        }
                                    }
                                },
                            },
                        ],
                    },
                    
                },
            }),
            500
        );
        return () => clearTimeout(timeOutId);
    }, [userName, search]);
    
    useEffect(() => {
        if(userOrganizationProfiles.find((userOrganizationProfile) => userOrganizationProfile.userName === pilot.name)) {
            setValue(userOrganizationProfiles.find((userOrganizationProfile) => userOrganizationProfile.userName === pilot.name)?.id ?? '');
        }
    }, [pilot.name, userOrganizationProfiles]);

    useEffect(() => {
        if(pilot.name !== ''){
            setUserName(pilot.name);
        }
    }, [pilot.name]);

    return ( <div className="flex w-full gap-2">
        <Combobox value={pilot.name}>
            <div className={'relative w-full'}>
                <Combobox.Input<'input', any>
                className="rounded border p-2 text-sm bg-slate-50 border-slate-300 focus:bg-white w-full"
                onChange={(event) => setUserName(event.target.value)}
                value={userName}
                onBlur={() => {
                    setUserName(pilot.name);
                }}
                placeholder="Search for an Users"
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    {userName ?
                        <XMarkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" onClick={() =>{
                            setUserName('');
                            setValue('');
                        }} /> :
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    }
                    
                </Combobox.Button>

                {userOrganizationProfiles?.length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {[...userOrganizationProfiles]?.sort((a,b) => 
                            a.userName.toLowerCase() > b.userName.toLowerCase() ? 1 : -1
                        ).map((userOrganizationProfile) => (
                        <Combobox.Option<'li', string>
                            key={userOrganizationProfile.id}
                            value={userOrganizationProfile?.userName}
                            onClick={() => { 
                                setValue(userOrganizationProfile.id);
                                setUserName(userOrganizationProfile.userName);
                            }}
                            className={({ active }) =>
                                classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')
                            }>
                            {({ active, selected }) => (
                            <>
                                <div className="flex">
                                    <span className={classNames('', selected && 'font-semibold')}>{userOrganizationProfile?.userName}</span>
                                </div>

                                {selected && (
                                <span
                                    className={classNames(
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                    active ? 'text-white' : 'text-indigo-600'
                                    )}>
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                                )}
                            </>
                            )}
                        </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    </div> );
    
}