import { useMemo } from 'react';
import { Table } from 'components/Table/Table';
import { WrenchScrewdriverIcon } from '@heroicons/react/24/solid';
import { useQuery } from '@apollo/client';
import { GetMroComponentsDocument } from 'graphql/generated';
import { FlyoutHookReturn } from 'components/Flyout/Flyout';
import EditComponent from 'views/Part145/WorkOrders/Order/WorkOrderItems/EditComponent';

export default function CraftComponents({
  mroCraftId,
  updateFlyout,
  closeFlyout,
  ...props
}: {
  mroCraftId: string;
  updateFlyout: FlyoutHookReturn['updateFlyout'];
  closeFlyout: FlyoutHookReturn['closeFlyout'];
}) {
  const { data: { mroComponents } = {} } = useQuery(GetMroComponentsDocument, {
    variables: { mroCraftId },
  });
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name', // accessor is the "key" in the data
        className: 'flex w-3/6 font-semibold pb-3',
        Cell: ({ value }) => value,
      },
      {
        Header: 'Serial #',
        accessor: 'serialNumber', // accessor is the "key" in the data
        className: 'flex w-1/6 mr-2',
        Cell: ({ value }: any) => {
          return (
            <div
              key={value}
              className="border-slate-300 bg-slate-50 text-slate-400 m-[0.125rem] flex items-center rounded px-1.5 py-0.5 border">
              <span className="font-bold">{value}</span>
            </div>
          );
        },
      },
      {
        Header: 'Make/Model/Year',
        accessor: (component) => {
          return {
            make: component.make,
            model: component.model,
            year: component.year,
          };
        }, // accessor is the "key" in the data
        className: 'flex w-2/6 pb-3',
        Cell: ({ value }) => value.make + ' ' + value.model + ' ' + value.year,
      },
    ],
    []
  );
  return (
    <>
      <div className="p-2 rounded-tr bg-white"></div>
      <div className="flex flex-col w-full p-4 gap-4 bg-slate-100 shadow-inner border-slate-100 border">
        {/* COMPONENTS */}
        <div className="flex flex-col w-full">
          <div className="flex items-center gap-2 p-4 bg-white border border-b-0 border-slate-200 rounded-t">
            <WrenchScrewdriverIcon className="h-6 w-6 p-1 bg-brand-pale text-brand-electric shadow rounded-sm" />
            <h1 className="text-brand-dark font-bold">Components</h1>
          </div>
          {mroComponents?.length > 0 ? (
            <Table
              className="w-full bg-white border rounded"
              columns={columns}
              data={mroComponents}
              onRowClick={(row) =>
                updateFlyout({
                  title: 'Update Component',
                  content: <EditComponent component={row} mroCraftId={mroCraftId} closeFlyout={closeFlyout} />,
                })
              }
            />
          ) : (
            <h2 className="font-bold text-sm text-center my-2">No Components Found</h2>
          )}
          <div className="bg-white border-t-0 border border-slate-200 rounded-b p-3 w-full"></div>
          <div className="mt-2 self-end flex gap-5">
            {/* To Do: Consider Allowing Components to be created from this view */}
            {/* {mroComponents?.length > 0 && 
                <button
                  className="flex items-center text-brand-dark hover:text-brand-electric text-xs font-semibold relative z-20 px-4 py-2 border border-slate-200 hover:border-brand-pale rounded bg-white hover:bg-brand-offwhite transition"
                  onClick={() => {}}>
                  <PuzzlePieceIcon className="h-3 w-3 mr-1 stroke-[0.15rem]" />
                  Create Component
                </button>
              } */}
          </div>
        </div>
      </div>
      <div className="bg-white border-t border-slate-300 rounded-b p-2 w-full"></div>
    </>
  );
}
